.footer {
  background-color: #edeefc;
  padding: 37px 0 30px;
  text-align: center;

  @include b(mobile) {
    padding: 32px 0 30px;
    text-align: left;
  }

  @include b(tablet) {
    padding: 46px 0 22px;
  }

  @include b(desktop) {
    padding: 46px 0 42px;
  }

  &__outer {

    @include b(mobile) {
      position: relative;
    }

    @include b(tablet) {
      max-width: 990px;
      margin-left: auto;
    }
  }

  &__social {
    padding-bottom: 31px;
    text-align: center;
    max-width: 86px;
    margin: 0 auto;

    @include b(mobile) {
      position: absolute;
      top: 4px;
      right: 0;
      padding-bottom: 0;
    }

    @include b(tablet) {
      top: 0;
    }

    @include b(desktop) {
      top: -12px;
    }
  }

  &__holder {
    font-size: 0;
    line-height: 0;
    margin: 0 -10px;
  }

  &__nav {
    padding-bottom: 22px;

    @include b(mobile) {
      display: inline-block;
      vertical-align: top;
      width: 36%;
      padding: 0 10px 20px;
    }

    @include b(tablet) {
      display: block;
      width: 100%;
    }

    &-item {
      padding-bottom: 14px;

      @include b(tablet) {
        font-size: 16px;
        line-height: 1.2;
        display: inline-block;
        vertical-align: top;
        padding-right: 24px;
        padding-bottom: 0;


        &:last-child {
          padding-right: 0;
        }
      }

      @include b(desktop) {
        padding-right: 53px;
      }
    }

    &-link {
      font-size: 15px;
      line-height: 1.2;
      color: #1f1f1f;
      @include b(tablet) {
        font-size: 16px;
        font-weight: 700;
      }

      &:hover {
        color: $accent;
      }
    }
  }

  &__info {

    @include b(mobile) {
      display: inline-block;
      vertical-align: top;
      width: 46%;
      padding: 0 10px;
    }

    @include b(tablet) {
      display: block;
      width: 100%;
      font-size: 0;
      line-height: 0;
    }

    &-item {
      padding-bottom: 14px;

      @include b(tablet) {
        font-size: 15px;
        line-height: 1.2;
        display: inline-block;
        vertical-align: top;
        padding-right: 63px;

        &:last-child {
          padding-right: 0;
        }
      }

      @include b(desktop) {
        padding-right: 73px;
      }
    }

    &-link {
      font-size: 15px;
      line-height: 1.2;
      color: #8688a8;

      &:hover {
        color: $accent;
      }
    }
  }
}
