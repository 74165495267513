// В этом файле должны быть стили для БЭМ-блока nav, его элементов, 
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...

.nav {
  position: fixed;
  top: 0;
  left: -100%;
  bottom: 0;
  right: 100%;
  z-index: 5;
  background-color: $accent;
  padding: 54px 15px 25px;
  transition: all .5s ease;
  overflow: hidden;
  overflow-y: auto;

  @include b(tablet) {
    margin-bottom: 40px;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    padding: 26px 10px 31px 45px;
    background-color: transparent;
    border: 4px solid $accent;
    overflow: visible;
  }

  @include b(desktop) {
    width: 309px;
  }

  &::before {

    @include b(tablet) {
      content: '';
      position: absolute;
      left: 6px;
      right: 7px;
      bottom: -14px;
      height: 14px;
      border-left: 4px solid $accent;
      border-right: 4px solid $accent;
      border-bottom: 4px solid $accent;
    }
  }

  &.show {
    left: 0;
    right: 0;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 11px;
    width: 15px;
    height: 15px;

    @include b(tablet) {
      display: none;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: -3px;
      width: 19px;
      border-bottom: 1px solid $white;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__list {
    margin: 0 auto;
    max-width: 140px;

    @include b(tablet) {
      max-width: 100%;
    }
  }

  &__item {

    &-link {
      color: $white;
      font-size: 15px;
      font-weight: 700;
      line-height: 2.8;
      border-bottom: 2px solid $white;
      text-transform: uppercase;

      @include b(tablet) {
        color: $default;
        border-bottom-color: #e3e3e3;
      }

      &:hover {

        @include b(tablet) {
          border-bottom-color: transparent;
        }
      }
    }
  }
}
