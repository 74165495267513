.articles-top {
  margin: 0 -10px;
  padding: 9px 10px 0;
  border-bottom: 1px solid #f5f5f5;

  @include b(tablet) {
    border-bottom: 0;
  }

  @include b(desktop) {
    margin: 0 -15px;
    padding: 0 0 32px;
    font-size: 0;
    line-height: 0;
  }

  &__col {
    margin-bottom: 33px;

    @include b(mobile) {
      margin-bottom: 66px;
    }

    @include b(tablet) {
      margin-bottom: 57px;
    }

    @include b(desktop) {
      margin-bottom: 0;
      padding: 0 15px;
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }
  }
}
