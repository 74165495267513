// В этом файле должны быть стили для БЭМ-блока search, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...

.search {

  &--main {

    @include b(desktop) {

      .search__title {
        font-size: 18px;
        line-height: 1.33;
      }
    }
  }

  &--large {

    .search__time {
      position: absolute;
      top: 9px;
      left: 10px;
      width: auto;

      @include b(mobile) {
        top: 19px;
        left: 20px;
      }
    }

    .search__time .time__data {
      font-family: $main-font;
      font-size: 11px;
      text-transform: uppercase;

      @include b(desktop) {
        margin-bottom: 0;
      }
    }

    .search__img {

      @include b(mobile) {
        object-fit: cover;
        height: 360px;
      }

      @include b(tablet) {
        height: 355px;
      }
    }

    .search__holder {
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 7px;

      @include b(mobile) {
        left: 21px;
        right: 21px;
        bottom: 7px;
      }

      @include b(tablet) {
        left: 28px;
        right: 28px;
        bottom: 38px;
      }
    }

    .search__info {
      margin-right: 21px;
      padding-left: 0;
      color: $white;
      width: auto;


      @include b(tablet) {
        margin-right: 17px;
      }

      .icon-svg {
        fill: $white;
      }
    }

    .search__title {
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 1.4;

      @include b(mobile) {
        margin-bottom: 16px;
        font-size: 26px;
      }

      @include b(tablet) {
        margin-bottom: 18px;
        font-size: 20px;
        line-height: 1.47;
      }
    }

    .search__desc {
      margin-bottom: 15px;

      @include b(mobile) {
        margin-bottom: 17px;
      }

      @include b(tablet) {
        margin-bottom: 22px;
        font-size: 17px;
      }

      @include b(desktop) {
        margin-bottom: 24px;
        line-height: 1.48;
      }

      .js-shave-char {
        margin-left: 8px;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        background-color: $accent;
        line-height: 10px;
        color: $white;
        font-size: 17px;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  &__top {
    margin: 0 -10px 22px;
    position: relative;
    z-index: 1;

    @include b(mobile) {
      margin: 0 -20px 23px;
    }

    @include b(tablet) {
      margin: 0 0 34px;
    }
  }

  &__link {
    margin-bottom: 22px;
    display: block;
    position: relative;
    z-index: 1;

    @include b(mobile) {
      margin-bottom: 25px;
    }

    @include b(tablet) {
      margin-bottom: 21px;
    }

    @include b(desktop) {
      margin-bottom: 15px;
    }

    &::before {
      @include b(desktop) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background-color: rgba($accent, .5);
        opacity: 0;
        transition: all .3s ease;
      }
    }

    &:hover::before {
      opacity: 1;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__holder {
    margin-bottom: 12px;
    font-size: 0;
    line-height: 0;

    @include b(tablet) {
      margin-bottom: 14px;
    }

    @include b(desktop) {
      margin-bottom: 15px;
    }
  }

  &__time {
    padding-right: 5px;
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    font-family: $second-font;
    color: $gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: .56px;

    @include b(tablet) {
      margin-bottom: 10px;
      width: 100%;
    }

    @include b(desktop) {
      margin-bottom: 0;
      width: 40%;
    }
  }

  &__info {
    padding-left: 5px;
    display: inline-block;
    vertical-align: middle;
    width: 25%;
    font-family: $second-font;
    color: $gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: .56px;

    @include b(tablet) {
      padding-right: 5px;
      padding-left: 0;
      width: 50%;
    }

    @include b(desktop) {
      padding-right: 0;
      padding-left: 5px;
      width: 30%;
    }

    .icon-svg {
      margin-top: -4px;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
      fill: $gray;
    }

    &-txt {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.3;
      width: calc(100% - 25px);
    }
  }

  &__topic {
    margin-bottom: 9px;
    display: block;
    color: $gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
  }

  &__title {
    margin-bottom: 11px;
    display: block;
    color: $default;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;

    @include b(tablet) {
      margin-bottom: 13px;
      font-size: 15px;
      line-height: 1.4;
    }

    @include b(desktop) {
      margin-bottom: 14px;
    }

    &:hover {
      color: $accent;
    }
  }

  &__desc {
    display: block;
    color: $default;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.4;
  }

  &__more {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;

    &:hover {
      color: $default;

      .search__more-icon,
      .search__more-icon::before,
      .search__more-icon::after {
        border-color: $default;
      }
    }

    &-icon {
      margin-right: 11px;
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      border: 2px solid $accent;
      border-radius: 50%;
      position: relative;
      z-index: 1;
      transition: all .3s ease-in-out;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 3px;
        margin-top: -3px;
        width: 6px;
        height: 6px;
        border-right: 2px solid $accent;
        border-bottom: 2px solid $accent;
        transform: rotate(-45deg);
        transition: all .3s ease-in-out;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -1px;
        right: 4px;
        width: 6px;
        border-bottom: 2px solid $accent;
        transition: all .3s ease-in-out;
      }
    }

    &-txt {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  .search--large .search__desc .js-shave-char {
    line-height: 13px;
  }
}
