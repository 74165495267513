.subscribe__form {

  &-holder {
    position: relative;

    @include b(tablet) {
      font-size: 0;
      line-height: 0;
    }
  }

  &-box {
    display: block;
    padding-bottom: 82px;

    @include b(mobile) {
      padding-bottom: 72px;
    }

    @include b(tablet) {
      padding-bottom: 0;
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 199px);
      padding-right: 29px;
      position: relative;
    }
  }

  &-icon {
    display: none;
    cursor: pointer;

    &:hover {

      svg {
        fill: $accent;
      }
    }

    svg {
      fill: #8a8a8a;
      transition: all .3s ease-in-out;
    }

    @include b(tablet) {
      display: block;
      position: absolute;
      top: 13px;
      left: 18px;
    }
  }

  &-input {
    font-family: $second-font;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    padding: 3px 16px 4px;
    height: 50px;

    @include b(tablet) {
      padding: 4px 16px 5px 51px;
      text-align: left;
    }
  }

  &-btn {
    max-width: 199px;
    margin: 0 auto;

    .btn {

      @-moz-document url-prefix() {
        padding: 15.5px 24px;
      }
    }

    @include b(tablet) {
      display: inline-block;
      vertical-align: top;
      width: 199px;
    }
  }

  &-txt {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    position: absolute;
    bottom: 67px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    @include b(mobile) {
      bottom: 82px;
    }

    @include b(tablet) {
      position: static;
      transform: translateX(0);
      padding-top: 22px;
    }
  }
}
