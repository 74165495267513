.reviews {
  background-color: #f5f5f5;
  margin: 0 -10px;
  padding: 65px 10px 29px;
  border-top: 1px solid #e3e3e3;

  @include b(mobile) {
    margin: 0;
    padding: 39px 0 39px;
    border: 1px solid #e3e3e3;
    margin-bottom: 54px;
  }

  @include b(tablet) {
    padding: 36px 0 45px;
  }

  @include b(desktop) {
    padding: 67px 0 47px;
  }

  @include b(large) {
    padding: 67px 27px 47px;
  }

  &__title {
    display: block;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    padding-bottom: 56px;

    @include b(mobile) {
      font-size: 26px;
      padding: 0 30px 43px;
    }

    @include b(tablet) {
      padding: 0 24px 53px;
    }
  }

  &__form {
    padding-bottom: 41px;

    @include b(mobile) {
      padding: 0 30px 40px;
    }

    @include b(tablet) {
      padding: 0 24px 36px;
    }

    @include b(desktop) {
      padding: 0 24px 22px;
    }

    &-head {

      @include b(desktop) {
        @include rlist;
        margin: 0 -20px;
      }
    }

    &-box {
      display: block;
      padding-bottom: 17px;

      @include b(desktop) {
        @include ulist;
        vertical-align: top;
        width: 50%;
        padding: 0 20px 17px;
      }
    }

    &-message {
      margin-bottom: 12px;

      @include b(desktop) {
        margin-bottom: 26px;
      }
    }

    &-btn {
      max-width: 144px;
      margin: 0 auto;

      .btn {
        padding: 16px 10px 16px;
      }

      @include b(mobile) {
        margin: 0;
        margin-left: auto;
      }
    }
  }

  &__holder {

    @include b(mobile) {
      padding: 0 10px;
    }

    @include b(tablet) {
      padding: 0 24px;
    }
  }

  &__item {
    border: 1px solid #e3e3e3;
    background-color: #fff;
    padding: 30px 20px 18px;
    margin-bottom: 31px;

    @include b(mobile) {
      padding: 32px 17px 40px;
    }

    @include b(tablet) {
      padding: 31px 57px 27px;
      margin-bottom: 27px;
    }

    @include b(desktop) {
      padding: 31px 57px 26px;
      margin-bottom: 22px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--answer {
      position: relative;
      margin-left: 31px;

      @include b(tablet) {
        margin-left: 38px;
      }

      @include b(desktop) {
        margin-left: 96px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 71px;
        left: -30px;
        width: 20px;
        height: 17px;
        border-left: 3px solid $accent;
        border-bottom: 3px solid $accent;

        @include b(mobile) {
          top: -15px;
        }

        @include b(tablet) {
          top: -12px;
          left: -33px;
        }

        @include b(desktop) {
          top: -17px;
          left: -29px;
        }
      }
    }

    &-head {
      @include rlist;
      padding-bottom: 16px;

      @include b(desktop) {
        padding-bottom: 14px;
      }
    }

    &-name {
      display: inline-block;
      vertical-align: bottom;
      font-size: 15px;
      font-weight: 700;
      line-height: 24px;
      padding-right: 23px;
    }

    &-date {
      display: inline-block;
      vertical-align: bottom;
      font-size: 14px;
      line-height: 24px;
      font-family: $second-font;
      color: #8a8a8a;
    }

    &-time {
      display: inline-block;
      padding-left: 10px;
    }

    &-descr {
      font-size: 15px;
      font-weight: 300;
      line-height: 21px;
      padding-bottom: 11px;

      @include b(tablet) {
        padding-bottom: 6px;
      }

      @include b(desktop) {
        line-height: 23px;
        padding-bottom: 11px;
      }
    }

    &-link {
      display: inline-block;
      font-size: 15px;
      font-weight: 300;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom-color: $accent;
      }
    }
  }
}
