select {
  //display: block;
  //@include clearSelect;
  //transition: none;
  //background-position: right 25px center;
  //background-repeat: no-repeat;
  //background-size: 29px 17px;
  //background-color: #fff;
}

.selectric {
  background-color: $white;
  border-color: #e3e3e3;

  .label {
    margin-left: 19px;
    padding-top: 10px;
    padding-bottom: 12px;
    color: $gray;
    font-size: 17px;
    line-height: 1.27;
    height: auto;
  }

  .button {
    bottom: 0;
    height: auto;
    background-color: transparent;

    &::after {
      border-top-color: $gray;
    }
  }

  &-items {
    border-color: #e3e3e3;
    background-color: $white;

    li {
      padding-left: 19px;
      color: $default;
      font-size: 17px;
      border-bottom: 1px solid $white;

      &.highlighted,
      &:hover {
        background-color: rgba($accent, .25);
      }
    }
  }
}
