.contacts {
  padding-bottom: 20px;

  @include b(mobile) {
    padding-bottom: 0;
  }

  @include b(desktop) {
    padding-bottom: 199px;
  }

  &__holder {
    padding: 48px 10px 37px;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    background-color: #f5f5f5;
    margin: 0 -10px;

    @include b(mobile) {
      padding: 33px 20px 44px;
      border-bottom: none;
      margin: 0 -20px;
    }

    @include b(tablet) {
      margin: 0;
      border: 1px solid #e3e3e3;
      padding: 42px 26px 44px;
    }

    @include b(desktop) {
      padding: 51px 55px 55px;
    }
  }

  &__title {
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 36px;

    @include b(mobile) {
      text-align: left;
      padding-bottom: 31px;
    }

    @include b(tablet) {
      font-size: 26px;
      line-height: 32px;
      padding-bottom: 33px;
    }

    @include b(desktop) {
      font-size: 32px;
      line-height: 42px;
      padding-bottom: 35px;
    }
  }

  &__item {
    @include rlist;

    @include b(tablet) {
      padding-left: 4px;
    }

    &-icon {
      @include ulist;
      vertical-align: top;
      width: 24px;
      height: 24px;
      margin-top: 3px;

      @include b(tablet) {
        margin-top: 5px;
      }

      svg {
        fill: #f34e2d;
      }
    }

    &-info {
      @include ulist;
      vertical-align: top;
      width: calc(100% - 24px);
      padding-left: 21px;
      font-size: 15px;
      line-height: 21px;

      @include b(tablet) {
        font-size: 17px;
        line-height: 27px;
        padding-left: 19px;
      }
    }

    &-name {
      display: block;
      color: #f34e2d;
      font-size: 17px;
      line-height: 27px;
      font-weight: 700;
    }

    &-phone {
      display: block;
      font-size: 17px;
      line-height: 27px;

      a {
        color: #1f1f1f;

        &:hover {
          color: $accent;
        }
      }
    }

    &-mail {
      display: block;
      font-size: 17px;
      line-height: 27px;

      a {
        border-bottom: 1px solid transparent;

        &:hover {
          border-bottom-color: $accent;
        }
      }
    }
  }

  &__subtitle {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 35px 0 25px;

    @include b(mobile) {
      text-align: left;
      padding: 47px 0 21px;
    }

    @include b(tablet) {
      font-size: 18px;
      line-height: 28px;
      padding: 43px 0 20px;
    }

    @include b(desktop) {
      padding: 67px 0 28px;
    }
  }
}
