.breadcrumbs {
  display: none;
  border-bottom: 1px solid #e3e3e3;
  padding: 9px 0;

  @include b(desktop) {
    display: block;
  }

  ul {
    margin: 0 -22px;
    font-size: 0;
    line-height: 0;
  }

  li {
    padding: 0 22px;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: top;
    color: $black;
    font-size: 15px;
    line-height: 1;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 6px;
      right: -6px;
      width: 14px;
      height: 1px;
      background-color: #8a8a8a;
    }

    &:after {
      content: '';
      position: absolute;
      top: 3px;
      right: -5px;
      width: 7px;
      height: 7px;
      border-right: 1px solid #8a8a8a;
      border-bottom: 1px solid #8a8a8a;
      transform: rotate(-45deg);
    }

    &:last-child {

      &:before,
      &:after {
        display: none;
      }
    }
  }

  a {
    color: $accent;
    line-height: .9;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom-color: $accent;
    }
  }
}
