.btn {
  @include trs;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  padding: 16px 24px;
  text-transform: uppercase;
  background: $accent;
  color: $white;
  border: none;
  border-radius: 1px;
  position: relative;
  width: 100%;
  display: block;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 12%;
    right: 13%;
    left: 13%;
    bottom: 0;
    z-index: -1;
    box-shadow: 0 3px 27px rgba(53, 57, 237, .65);
    transition: all .3s ease;
  }

  &:hover,
  &.active {
    color: $white;

    &::before {
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }

  &.disabled,
  &[disabled='disabled'],
  &[disabled] {
    background: $gray;
    color: $medium-gray;
    cursor: not-allowed;
    border-color: $medium-gray;

    &::before {
      display: none;
    }
  }

  &--orange {
    background-color: $orange;

    &::before {
      z-index: -1;
      box-shadow: 0 3px 27px rgba(243, 78, 45, .65);
    }

    &:hover,
    .active {

      &::before {
        opacity: 0;
      }
    }
  }

  &--border {
    border: 1px solid $white;
    background-color: transparent;
    box-shadow: none;

    &::before {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
      background-color: $white;
      top: 0;
      left: auto;
      right: 0;
      z-index: -1;
      transition: all .3s ease;
    }

    &:hover,
    .active {
      color: $accent;
      background-color: transparent;
      box-shadow: none;

      &::after {
        width: 100%;
        left: 0;
      }
    }
  }
}
