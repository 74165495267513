.icon {

  &-search {
    width: 25px;
    height: 25px;
  }

  &-email {
    width: 17px;
    height: 20px;
  }

  &-postbox {
    width: 33px;
    height: 33px;
  }

  &-logo {
    width: 37px;
    height: 37px;
  }

  &-subscription {
    width: 19px;
    height: 18px;
  }

  &-vk {
    width: 21px;
    height: 13px;
  }

  &-fb {
    width: 10px;
    height: 19px;
  }

  &-zen {
    width: 23px;
    height: 23px;
  }

  &-pin {
    width: 20px;
    height: 24px;
  }

  &-book {
    width: 24px;
    height: 24px;
  }

  &-about {
    width: 73px;
    height: 75px;
  }

  &-views {
    width: 20px;
    height: 13px;
  }

  &-comments {
    width: 14px;
    height: 14px;
  }

  &-base {
    width: 31px;
    height: 25px;
  }

  &-doc {
    width: 13px;
    height: 15px;
  }
}
