// В этом файле должны быть стили для БЭМ-блока base, его элементов, 
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...

.base {
  padding: 40px 0 32px;

  &--mobile-hidden {
    display: none;

    @include b(tablet) {
      display: block;
    }
  }

  @include b(mobile) {
    margin-top: 21px;
    margin-bottom: 11px;
    padding: 36px 0 33px;
    border: 3px solid $orange;
  }

  @include b(tablet) {
    margin-top: 0;
    margin-bottom: 40px;
  }

  @include b(desktop) {
    margin-bottom: 21px;
    padding: 46px 0 45px;
    border-width: 4px;
  }

  &__title {
    margin-bottom: 25px;
    display: block;
    color: $orange;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;

    @include b(desktop) {
      margin-bottom: 42px;
    }

    .icon-svg {
      margin-right: 15px;
      display: inline-block;
      vertical-align: middle;
      fill: $orange;

      @include b(mobile) {
        margin-right: 14px;
      }
    }

    &-txt {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__slider {
    margin-bottom: 26px;
    padding: 0 9px;

    @include b(mobile) {
      margin-bottom: 6px;
      padding: 0 14px;
    }

    @include b(tablet) {
      margin-bottom: 11px;
    }

    @include b(desktop) {
      margin-bottom: 23px;
      padding: 0 21px;
      font-size: 0;
      line-height: 0;
    }

    .slick-current .base__slide {

      @include b(desktop) {
        border-right: 1px solid #d6d6d6;
      }
    }
  }

  &__slide {
    padding: 0 10px;

    @include b(mobile) {
      padding: 0 15px;
    }

    @include b(desktop) {
      padding: 0 25px;
    }

    &-item {
      margin-bottom: 17px;

      @include b(mobile) {
        margin-bottom: 28px;
      }

      @include b(tablet) {
        margin-bottom: 24px;
      }

      @include b(desktop) {
        margin-bottom: 20px;
      }
    }

    &-link {
      display: inline-block;
      vertical-align: top;
      font-size: 0;
      line-height: 0;

      .icon-svg {
        margin-right: 13px;
        display: inline-block;
        vertical-align: top;
        fill: #bebebe;

        @include b(tablet) {
          margin-right: 11px;
        }
      }
    }

    &-txt {
      margin-top: -4px;
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 26px);
      color: $default;
      font-size: 15px;
      line-height: 1.4;

      @include b(tablet) {
        margin-top: -2px;
        font-size: 17px;
        line-height: 1.24;
        width: calc(100% - 24px);
      }

      @include b(desktop) {
        line-height: 1.41;
      }
    }
  }

  &__nav {
    padding: 0 44px;
    position: relative;

    @include b(mobile) {
      margin: 0 auto;
      max-width: 300px;
    }
  }
}
