.base-block {
  margin: 0 -10px;
  padding-top: 9px;

  @include b(mobile) {
    margin: 0 -20px;
  }

  @include b(tablet) {
    padding-top: 0;
    margin: 0;

    .base {
      display: none;
    }
  }

  @include b(large) {
    padding-top: 30px;
  }

  &__hero {
    position: relative;
    z-index: 0;
    padding: 30px 17px 39px;
    background-size: cover;
    background-position: center;

    @include b(mobile) {
      @include rlist;
      padding: 36px 36px 39px;
    }

    @include b(tablet) {
      padding: 31px 36px 69px;
    }

    @include b(large) {
      padding: 61px 44px 55px;
    }

    &:before {
      content: '';
      background-color: rgba(#2c2d4d, .75);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    &-img {
      margin-bottom: 17px;

      @include b(mobile) {
        @include ulist;
        vertical-align: top;
        width: 255px;
        margin: 15px 0 0;
      }

      @include b(tablet) {
        display: block;
        width: 100%;
        margin: 0 0 22px;
      }

      @include b(large) {
        @include ulist;
        vertical-align: top;
        width: 260px;
        margin: 0;
      }

      img {
        display: block;
        width: 225px;
        margin: 0 auto;

        @include b(tablet) {
          width: 210px;
        }

        @include b(large) {
          width: 260px;
        }
      }
    }

    &-content {
      text-align: center;

      @include b(mobile) {
        @include ulist;
        vertical-align: top;
        width: calc(100% - 255px);
        padding-left: 47px;
        text-align: left;
      }

      @include b(tablet) {
        display: block;
        width: 100%;
        padding-left: 0;
        text-align: center;
      }

      @include b(large) {
        @include ulist;
        vertical-align: top;
        width: calc(100% - 260px);
        text-align: left;
        padding: 30px 54px 0 37px;
      }
    }

    &-title {
      display: block;
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
      color: #fff;

      @include b(mobile) {
        font-size: 32px;
        line-height: 38px;
        padding-bottom: 22px;
      }

      @include b(tablet) {
        padding-bottom: 9px;
      }

      @include b(desktop) {
        font-size: 42px;
        line-height: 52px;
        padding-bottom: 22px;
      }
    }

    &-subtitle {
      display: block;
      font-size: 16px;
      line-height: 42px;
      color: #fff;
      font-weight: 700;
      padding-bottom: 21px;

      @include b(mobile) {
        font-size: 26px;
        line-height: 42px;
        padding-bottom: 26px;
      }

      @include b(tablet) {
        padding-bottom: 27px;
      }

      @include b(desktop) {
        font-size: 32px;
        line-height: 42px;
        padding-bottom: 65px;
      }
    }

    &-ctrl {
      @include rlist;
      margin: 0 -7px;
      padding: 0 9px;

      @include b(mobile) {
        padding: 0;
        margin: 0 -10px;
        max-width: 294px;
      }

      @include b(tablet) {
        margin: 0 auto;
      }

      @include b(large) {
        max-width: 282px;
        margin: 0;
        margin-left: auto;
      }
    }

    &-demo {
      @include ulist;
      vertical-align: top;
      padding: 0 7px;
      width: calc(100% - 114px);

      @include b(mobile) {
        padding: 0 10px;
        width: calc(100% - 120px);
      }

      @include b(tablet) {
        padding: 0 7px;
      }

      .btn {
        padding: 16px 10px 16px;
      }
    }

    &-buy {
      @include ulist;
      vertical-align: top;
      padding: 0 7px;
      width: 114px;

      @include b(mobile) {
        width: 120px;
        padding: 0 10px;
      }

      @include b(tablet) {
        padding: 0 7px;
        width: 114px;
      }

      .btn {
        padding: 16px 10px 16px;
      }
    }
  }

  &__content {
    background-color: #f5f5f5;
    padding: 39px 10px 13px;
    border: 1px solid #e3e3e3;
    border-width: 0 0 1px;
    margin-bottom: 37px;

    @include b(mobile) {
      padding: 36px 20px 20px;
      margin-bottom: 62px;
    }

    @include b(tablet) {
      padding: 37px 24px 15px;
      margin-bottom: 67px;
    }

    @include b(desktop) {
      border-width: 0 1px 1px;
    }

    @include b(large) {
      margin-bottom: 71px;
      padding: 69px 24px 61px;

      .static-content {
        max-width: 760px;
        margin: 0 auto;
      }
    }
  }

  &__holder {
    text-align: center;
    padding: 0 10px;

    @include b(mobile) {
      text-align: left;
      padding: 0 20px 41px;
    }

    @include b(tablet) {
      padding: 0 0 36px;
      text-align: center;
    }

    @include b(desktop) {
      padding: 0 0 73px;
    }
  }

  // для списка внизу страницы база данных
  &__holder_list {
    text-align: left;
    padding: 0 10px;

    @include b(mobile) {
      text-align: left;
      padding: 0 20px 41px;
    }

    @include b(tablet) {
      padding: 0 0 36px;
      text-align: left;
    }

    @include b(desktop) {
      padding: 0 0 73px;
    }
  }

  &__title {
    display: block;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 43px;

    @include b(mobile) {
      font-size: 26px;
      line-height: 32px;
    }

    @include b(tablet) {
      padding-bottom: 48px;
      text-align: left;
    }

    @include b(desktop) {
      font-size: 32px;
      line-height: 24px;
      padding-bottom: 79px;
    }
  }

  &__row {
    @include rlist;
    margin: 0 -15px;

    @include b(desktop) {
      margin: 0 -10px;
    }
  }

  &__col {
    padding-bottom: 62px;

    @include b(mobile) {
      @include ulist;
      vertical-align: top;
      width: 33.33%;
      padding: 0 15px 48px;
    }

    @include b(tablet) {
      width: 50%;
      padding: 0 15px 55px;
    }

    @include b(desktop) {
      width: 33.33%;
      padding: 0 10px 55px;
    }
  }

  &__item {
    display: inline-block;

    @include b(mobile) {
      text-align: center;
    }

    &:hover {

      .base-block__item-name {
        color: $accent;
      }
    }

    &-img {
      display: block;
      max-width: 300px;
      margin: 0 auto 22px;

      @include b(mobile) {
        margin: 0 auto 13px;
      }

      @include b(tablet) {
        margin: 0 auto 24px;
      }

      @include b(desktop) {
        margin: 0 auto 35px;
      }

      img {
        display: block;
        margin: 0 auto;
        width: 185px;

        @include b(mobile) {
          height: 210px;
          width: auto;
        }

        @include b(tablet) {
          height: 236px;
        }

        @include b(desktop) {
          height: 256px;
        }
      }
    }

    &-name {
      transition: all .3s ease-in-out;
      display: inline-block;
      color: #1f1f1f;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      padding: 0 40px;
      max-width: 300px;
      margin: 0 auto;

      span {
        display: inline-block;
      }

      @include b(mobile) {
        padding: 0 9px;
      }

      @include b(tablet) {
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        padding: 0 4px;
      }

      @include b(desktop) {
        padding: 0;
      }
    }
  }
}
