*,
*:before,
*:after
{
    box-sizing: border-box;

    outline: 0;
}

body
{
    display: flex;
    flex-direction: column; 

    min-width: 320px;
    height: 100%;
}

html
{
    height: 100%;
}

.container
{
    max-width: 1366px;
    margin: 0 auto; 
    padding: 0 10px;
}
.container:after
{
    display: block;
    clear: both; 

    content: ' ';
}
@media (min-width: 640px)
{
    .container
    {
        padding: 0 20px;
    }
}

.wrapper
{
    overflow: hidden; 

    flex: 1 0 auto;
}

@media (min-width: 767px)
{
    .page
    {
        padding-top: 33px;
    }
}

@media (min-width: 1024px)
{
    .page
    {
        padding-top: 61px;
    }
}

@media (min-width: 767px)
{
    .sidebar
    {
        display: block;
        float: left;

        width: 237px; 
        padding-right: 15px;
    }
}

@media (min-width: 1024px)
{
    .sidebar
    {
        width: 324px;
    }
}

@media (min-width: 767px)
{
    .article
    {
        float: right;

        width: calc(100% - 237px); 
        padding-left: 15px;
    }
}

@media (min-width: 1024px)
{
    .article
    {
        width: calc(100% - 324px);
    }
}

body
{
    font-family: 'Roboto', 'sans-serif';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2; 

    color: #1f1f1f;
    background: #fff;
}

ul,
ol
{
    margin: 0;
    padding: 0;

    list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6
{
    font-family: 'Roboto', 'sans-serif';
    font-weight: 700;
    line-height: 1.24; 

    margin: 0;
    padding: 0;

    color: #1f1f1f;
}

h1
{
    font-size: 42px;
}

h2
{
    font-size: 36px;
}

h3
{
    font-size: 30px;
}

h4
{
    font-size: 28px;
}

h5
{
    font-size: 24px;
}

h6
{
    font-size: 20px;
}

p
{
    margin: 0; 
    padding: 0;
}

hr
{
    overflow: hidden;

    height: 1px;
    margin: 32px 0; 

    border: 0;
    background: #e9e9e9;
}

q,
blockquote
{
    font-style: italic; 

    display: block;

    text-align: center;
}

strong,
b
{
    font-weight: 700;
}

a
{
    transition: all .3s ease-in-out;
    text-decoration: none; 

    color: #191fcc;
}
a.button
{
    text-decoration: none;
}
a.button:hover
{
    text-decoration: none;
}

[class*='icon-']
{
    display: inline-block;

    vertical-align: top;
}

img[style*='float: left'],
img[style*='float:left'],
img[align='left']
{
    margin: 0 20px 5px 0;
}

img[style*='float: right'],
img[style*='float:right'],
img[align='right']
{
    margin: 0 0 5px 20px;
}

.static-content blockquote
{
    padding-left: 20px; 

    text-align: left;

    border-left: 2px solid #ccc;
}

.static-content ul
{
    margin: 20px 0;
}
.static-content ul li
{
    position: relative;

    padding-left: 20px;
}
.static-content ul li:before
{
    position: absolute;
    top: 50%;
    left: 0;

    display: block;

    width: 5px;
    height: 5px;
    margin-top: -2px;

    content: '';
    transform: translateY(-50%); 

    border-radius: 50%;
    background: #1f1f1f;
}

.static-content ol
{
    margin: 20px 0;

    list-style-position: inside;
    list-style-type: decimal;

    counter-reset: item;
}
.static-content ol li
{
    position: relative; 

    display: block;
}
.static-content ol li:before
{
    font-weight: 700; 

    display: inline;

    content: counters(item, '.') '. ';
    counter-increment: item;
    vertical-align: top;
}

.static-content p
{
    font-size: 15px;
    font-weight: 300;
    line-height: 21px; 

    margin-bottom: 21px;
}
@media (min-width: 640px)
{
    .static-content p
    {
        font-size: 17px;
        line-height: 25px;

        margin-bottom: 25px;
    }
}
@media (min-width: 1024px)
{
    .static-content p
    {
        font-weight: 400; 
        line-height: 27px;

        margin-bottom: 27px;
    }
}
.static-content p + img
{
    margin-top: 8px;
}
@media (min-width: 767px)
{
    .static-content p + img
    {
        margin-top: 25px;
    }
}

.static-content table
{
    width: 100%;

    border-collapse: collapse;
}
.static-content table th
{
    font-weight: 700;

    padding: 5px; 

    text-align: left;

    border-bottom: 1px solid #000;
}
.static-content table td
{
    padding: 5px;
}
.static-content table tr:nth-child(odd) td
{
    background-color: #f4f4f4;
}

.static-content img
{
    max-width: 100%; 
    margin-bottom: 12px;
}
@media (min-width: 640px)
{
    .static-content img
    {
        margin-bottom: 20px;
    }
}
@media (min-width: 767px)
{
    .static-content img
    {
        margin-bottom: 13px;
    }
}
@media (min-width: 767px)
{
    .static-content img
    {
        height: 320px;

        object-fit: cover;
    }
}
@media (min-width: 1024px)
{
    .static-content img
    {
        margin-bottom: 20px;
    }
}
.static-content img + p
{
    margin-top: 12px;
}
@media (min-width: 640px)
{
    .static-content img + p
    {
        margin-top: 20px;
    }
}
@media (min-width: 767px)
{
    .static-content img + p
    {
        margin-top: 26px;
    }
}
@media (min-width: 1024px)
{
    .static-content img + p
    {
        margin-top: 41px;
    }
}

.upper
{
    text-transform: uppercase;
}

.btn
{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;

    position: relative;
    z-index: 1; 

    display: block;

    width: 100%;
    padding: 16px 24px;

    cursor: pointer;
    transition: all .3s ease-in-out;
    text-align: center;
    vertical-align: top;
    text-transform: uppercase;

    color: #fff;
    border: none;
    border-radius: 1px;
    background: #3539ed;
}
.btn::before
{
    position: absolute;
    z-index: -1;
    top: 12%;
    right: 13%;
    bottom: 0;
    left: 13%;

    content: '';
    transition: all .3s ease; 

    box-shadow: 0 3px 27px rgba(53, 57, 237, .65);
}
.btn:hover,
.btn.active
{
    color: #fff;
}
.btn:hover::before,
.btn.active::before
{
    opacity: 0;
}
.btn:focus
{
    outline: none;
}
.btn.disabled,
.btn[disabled='disabled'],
.btn[disabled]
{
    cursor: not-allowed;

    color: #ccc;
    border-color: #ccc; 
    background: #8a8a8a;
}
.btn.disabled::before,
.btn[disabled='disabled']::before,
.btn[disabled]::before
{
    display: none;
}
.btn--orange
{
    background-color: #f34e2d;
}
.btn--orange::before
{
    z-index: -1;

    box-shadow: 0 3px 27px rgba(243, 78, 45, .65);
}
.btn--orange:hover::before,
.btn--orange .active::before
{
    opacity: 0;
}
.btn--border
{
    border: 1px solid #fff;
    background-color: transparent;
    box-shadow: none;
}
.btn--border::before
{
    display: none;
}
.btn--border::after
{
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: auto;

    width: 0;
    height: 100%;

    content: '';
    transition: all .3s ease; 

    background-color: #fff;
}
.btn--border:hover,
.btn--border .active
{
    color: #3539ed;
    background-color: transparent;
    box-shadow: none;
}
.btn--border:hover::after,
.btn--border .active::after
{
    left: 0; 

    width: 100%;
}

.checkbox
{
    display: block;

    cursor: pointer;
}
.checkbox input[type='checkbox']
{
    display: none;
}
.checkbox input[type='checkbox']:checked + span:after
{
    opacity: 1;
}
.checkbox span
{
    font-size: 16px;
    line-height: 1.2;

    position: relative; 

    display: block;

    padding: 0 0 0 30px;
}
.checkbox span:before
{
    position: absolute;
    top: 50%;
    left: 0;

    display: block;

    width: 16px;
    height: 16px;
    margin: -8px 0 0 0;

    content: '';

    background: #ddd;
}
.checkbox span:after
{
    position: absolute;
    top: 50%;
    left: 5px;

    display: block;

    width: 5px;
    height: 8px;
    margin: -5px 0 0 0;

    content: '';
    transition: all .3s ease-in-out; 
    transform: rotate(45deg);

    opacity: 0;
    border-right: 2px solid #3539ed;
    border-bottom: 2px solid #3539ed;
}

textarea,
select,
.input
{
    font-size: 17px;
    line-height: 2.47;

    display: block;

    width: 100%;
    padding: 1px 16px 2px;

    transition: all .3s ease-in-out;
    letter-spacing: .68px; 

    color: #1f1f1f;
    border: 1px solid #e3e3e3;
    border-radius: 0;
    background-color: #fff;

    appearance: none;
}
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder,
.input::-webkit-input-placeholder
{
    color: #8a8a8a;
}
textarea:-moz-placeholder,
select:-moz-placeholder,
.input:-moz-placeholder
{
    opacity: 1; 
    color: #8a8a8a;
}
textarea::-moz-placeholder,
select::-moz-placeholder,
.input::-moz-placeholder
{
    opacity: 1; 
    color: #8a8a8a;
}
textarea:-ms-input-placeholder,
select:-ms-input-placeholder,
.input:-ms-input-placeholder
{
    color: #8a8a8a;
}
textarea:focus:-moz-placeholder,
select:focus:-moz-placeholder,
.input:focus:-moz-placeholder
{
    opacity: 1; 
    color: transparent;
}
textarea:focus::-moz-placeholder,
select:focus::-moz-placeholder,
.input:focus::-moz-placeholder
{
    opacity: 1; 
    color: transparent;
}
textarea:focus::-webkit-input-placeholder,
select:focus::-webkit-input-placeholder,
.input:focus::-webkit-input-placeholder
{
    color: transparent;
}
textarea:focus:-ms-input-placeholder,
select:focus:-ms-input-placeholder,
.input:focus:-ms-input-placeholder
{
    color: transparent;
}
textarea:focus::placeholder,
select:focus::placeholder,
.input:focus::placeholder
{
    color: transparent;
}
textarea:focus,
select:focus,
.input:focus
{
    border-color: #3539ed;
    background-color: #fff;
}
textarea.disabled,
textarea[disabled='disabled'],
textarea[disabled],
select.disabled,
select[disabled='disabled'],
select[disabled],
.input.disabled,
.input[disabled='disabled'],
.input[disabled]
{
    background-color: #f3f3f3;
}
textarea--inverse,
select--inverse,
.input--inverse
{
    font-family: 'Oswald';
    font-size: 16px;
    font-weight: 300;
    line-height: 1.31;
}

textarea
{
    line-height: 1.4; 

    height: 166px;
    padding: 28px 16px 2px;

    resize: none;
}

input[type='file']
{
    display: none;
}
input[type='file'] + label
{
    font-size: 14px;
    line-height: 24px;

    display: block;

    padding: 25px 15px;

    transition: all .3s ease-in-out; 
    text-align: center;

    color: #8a8a8a;
    border: 1px dashed #c4c4c4;
    background: #fff;
}
input[type='file'] + label:hover
{
    border-color: #696969;
    background: #e9e9e9;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    input
    {
        height: 46px;
    }
}

.radio
{
    display: block;

    cursor: pointer;
}
.radio input[type='radio']
{
    display: none;
}
.radio input[type='radio']:checked + span:after
{
    opacity: 1;
}
.radio span
{
    font-size: 16px;
    line-height: 1.2;

    position: relative; 

    display: block;

    padding: 0 0 0 30px;
}
.radio span:before
{
    position: absolute;
    top: 50%;
    left: 0;

    display: block;

    width: 16px;
    height: 16px;
    margin: -8px 0 0 0;

    content: '';

    border-radius: 50%;
    background: #ddd;
}
.radio span:after
{
    position: absolute;
    top: 50%;
    left: 4px;

    display: block;

    width: 8px;
    height: 8px;
    margin: -4px 0 0 0;

    content: '';
    transition: all .3s ease-in-out; 

    opacity: 0;
    border-radius: 50%;
    background: #3539ed;
}

.selectric
{
    border-color: #e3e3e3; 
    background-color: #fff;
}
.selectric .label
{
    font-size: 17px;
    line-height: 1.27;

    height: auto; 
    margin-left: 19px;
    padding-top: 10px;
    padding-bottom: 12px;

    color: #8a8a8a;
}
.selectric .button
{
    bottom: 0;

    height: auto;

    background-color: transparent;
}
.selectric .button::after
{
    border-top-color: #8a8a8a;
}
.selectric-items
{
    border-color: #e3e3e3;
    background-color: #fff;
}
.selectric-items li
{
    font-size: 17px;

    padding-left: 19px;

    color: #1f1f1f;
    border-bottom: 1px solid #fff;
}
.selectric-items li.highlighted,
.selectric-items li:hover
{
    background-color: rgba(53, 57, 237, .25);
}

.icon-search
{
    width: 25px;
    height: 25px;
}

.icon-email
{
    width: 17px;
    height: 20px;
}

.icon-postbox
{
    width: 33px;
    height: 33px;
}

.icon-logo
{
    width: 37px;
    height: 37px;
}

.icon-subscription
{
    width: 19px;
    height: 18px;
}

.icon-vk
{
    width: 21px;
    height: 13px;
}

.icon-fb
{
    width: 10px;
    height: 19px;
}

.icon-zen
{
    width: 23px;
    height: 23px;
}

.icon-pin
{
    width: 20px;
    height: 24px;
}

.icon-book
{
    width: 24px;
    height: 24px;
}

.icon-about
{
    width: 73px;
    height: 75px;
}

.icon-views
{
    width: 20px;
    height: 13px;
}

.icon-comments
{
    width: 14px;
    height: 14px;
}

.icon-base
{
    width: 31px;
    height: 25px;
}

.icon-doc
{
    width: 13px;
    height: 15px;
}

.row
{
    font-size: 0;
    line-height: 0;

    margin-right: -15px; 
    margin-left: -15px;
}

.col
{
    font-size: 16px;
    line-height: 1.2; 

    display: inline-block;

    width: 100%;
    padding: 0 15px;

    vertical-align: top;
}

@media (min-width: 320px)
{
    .xs-12
    {
        width: 100%;
    }
    .xs-11
    {
        width: 91.66667%;
    }
    .xs-10
    {
        width: 83.33333%;
    }
    .xs-9
    {
        width: 75%;
    }
    .xs-8
    {
        width: 66.66667%;
    }
    .xs-7
    {
        width: 58.33333%;
    }
    .xs-6
    {
        width: 50%;
    }
    .xs-5
    {
        width: 41.66667%;
    }
    .xs-4
    {
        width: 33.33333%;
    }
    .xs-3
    {
        width: 25%;
    }
    .xs-2
    {
        width: 16.66667%;
    }
    .xs-1
    {
        width: 8.33333%;
    }
    .offset-xs-11
    {
        margin-left: 91.66667%;
    }
    .offset-xs-10
    {
        margin-left: 83.33333%;
    }
    .offset-xs-9
    {
        margin-left: 75%;
    }
    .offset-xs-8
    {
        margin-left: 66.66667%;
    }
    .offset-xs-7
    {
        margin-left: 58.33333%;
    }
    .offset-xs-6
    {
        margin-left: 50%;
    }
    .offset-xs-5
    {
        margin-left: 41.66667%;
    }
    .offset-xs-4
    {
        margin-left: 33.33333%;
    }
    .offset-xs-3
    {
        margin-left: 25%;
    }
    .offset-xs-2
    {
        margin-left: 16.66667%;
    }
    .offset-xs-1
    {
        margin-left: 8.33333%;
    }
}

@media (min-width: 768px)
{
    .sm-12
    {
        width: 100%;
    }
    .sm-11
    {
        width: 91.66667%;
    }
    .sm-10
    {
        width: 83.33333%;
    }
    .sm-9
    {
        width: 75%;
    }
    .sm-8
    {
        width: 66.66667%;
    }
    .sm-7
    {
        width: 58.33333%;
    }
    .sm-6
    {
        width: 50%;
    }
    .sm-5
    {
        width: 41.66667%;
    }
    .sm-4
    {
        width: 33.33333%;
    }
    .sm-3
    {
        width: 25%;
    }
    .sm-2
    {
        width: 16.66667%;
    }
    .sm-1
    {
        width: 8.33333%;
    }
    .offset-sm-11
    {
        margin-left: 91.66667%;
    }
    .offset-sm-10
    {
        margin-left: 83.33333%;
    }
    .offset-sm-9
    {
        margin-left: 75%;
    }
    .offset-sm-8
    {
        margin-left: 66.66667%;
    }
    .offset-sm-7
    {
        margin-left: 58.33333%;
    }
    .offset-sm-6
    {
        margin-left: 50%;
    }
    .offset-sm-5
    {
        margin-left: 41.66667%;
    }
    .offset-sm-4
    {
        margin-left: 33.33333%;
    }
    .offset-sm-3
    {
        margin-left: 25%;
    }
    .offset-sm-2
    {
        margin-left: 16.66667%;
    }
    .offset-sm-1
    {
        margin-left: 8.33333%;
    }
}

@media (min-width: 1025px)
{
    .md-12
    {
        width: 100%;
    }
    .md-11
    {
        width: 91.66667%;
    }
    .md-10
    {
        width: 83.33333%;
    }
    .md-9
    {
        width: 75%;
    }
    .md-8
    {
        width: 66.66667%;
    }
    .md-7
    {
        width: 58.33333%;
    }
    .md-6
    {
        width: 50%;
    }
    .md-5
    {
        width: 41.66667%;
    }
    .md-4
    {
        width: 33.33333%;
    }
    .md-3
    {
        width: 25%;
    }
    .md-2
    {
        width: 16.66667%;
    }
    .md-1
    {
        width: 8.33333%;
    }
    .offset-md-11
    {
        margin-left: 91.66667%;
    }
    .offset-md-10
    {
        margin-left: 83.33333%;
    }
    .offset-md-9
    {
        margin-left: 75%;
    }
    .offset-md-8
    {
        margin-left: 66.66667%;
    }
    .offset-md-7
    {
        margin-left: 58.33333%;
    }
    .offset-md-6
    {
        margin-left: 50%;
    }
    .offset-md-5
    {
        margin-left: 41.66667%;
    }
    .offset-md-4
    {
        margin-left: 33.33333%;
    }
    .offset-md-3
    {
        margin-left: 25%;
    }
    .offset-md-2
    {
        margin-left: 16.66667%;
    }
    .offset-md-1
    {
        margin-left: 8.33333%;
    }
}

@media (min-width: 1266px)
{
    .lg-12
    {
        width: 100%;
    }
    .lg-11
    {
        width: 91.66667%;
    }
    .lg-10
    {
        width: 83.33333%;
    }
    .lg-9
    {
        width: 75%;
    }
    .lg-8
    {
        width: 66.66667%;
    }
    .lg-7
    {
        width: 58.33333%;
    }
    .lg-6
    {
        width: 50%;
    }
    .lg-5
    {
        width: 41.66667%;
    }
    .lg-4
    {
        width: 33.33333%;
    }
    .lg-3
    {
        width: 25%;
    }
    .lg-2
    {
        width: 16.66667%;
    }
    .lg-1
    {
        width: 8.33333%;
    }
    .offset-lg-11
    {
        margin-left: 91.66667%;
    }
    .offset-lg-10
    {
        margin-left: 83.33333%;
    }
    .offset-lg-9
    {
        margin-left: 75%;
    }
    .offset-lg-8
    {
        margin-left: 66.66667%;
    }
    .offset-lg-7
    {
        margin-left: 58.33333%;
    }
    .offset-lg-6
    {
        margin-left: 50%;
    }
    .offset-lg-5
    {
        margin-left: 41.66667%;
    }
    .offset-lg-4
    {
        margin-left: 33.33333%;
    }
    .offset-lg-3
    {
        margin-left: 25%;
    }
    .offset-lg-2
    {
        margin-left: 16.66667%;
    }
    .offset-lg-1
    {
        margin-left: 8.33333%;
    }
}

.no-gutter
{
    margin-right: 0; 
    margin-left: 0;
}
.no-gutter .col
{
    padding: 0;
}

.search
{
    position: relative;
    z-index: 1;
}
.search__btn
{
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;

    width: 33px; 
    padding: 0;

    background-color: transparent;
}
@media (min-width: 767px)
{
    .search__btn
    {
        right: 7px;
    }
}
.search__btn::before
{
    display: none;
}
.search__btn .icon-svg
{
    transition: all .2s ease; 

    fill: #1f1f1f;
}
.search__btn:hover .icon-svg
{
    fill: #3539ed;
}
.search .input
{
    font-size: 15px;
    line-height: 1.33;

    width: 100%; 
    padding: 10px 45px 11px;

    text-align: center;
    letter-spacing: normal;
}
@media (min-width: 640px)
{
    .search .input
    {
        padding-left: 18px;

        text-align: left;
    }
}

.email
{
    position: relative;
    z-index: 1; 

    display: block;
}
.email-icon
{
    position: absolute;
    top: 50%;
    left: 18px;

    display: none;

    width: 17px;
    height: 20px; 
    margin-top: -10px;
}
@media (min-width: 767px)
{
    .email-icon
    {
        display: block;
    }
}
.email-icon .icon-svg
{
    fill: #8a8a8a;
}
.email .input
{
    padding-top: 12px;
    padding-bottom: 14px;

    text-align: center;
}
@media (min-width: 767px)
{
    .email .input
    {
        padding-left: 53px;

        text-align: left;
    }
}

.slick-arrow
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    z-index: 1;
    top: 50%;

    width: 10px;
    height: 16px;
    margin: -8px 0 0;
    padding: 0;

    cursor: pointer;
    transition: all .3s ease-in-out; 

    border: 0;
    background: transparent;
    box-shadow: none;
}
.slick-arrow::before
{
    position: absolute;
    top: 50%;

    width: 12px;
    height: 12px;
    margin-top: -6px;

    content: '';

    border-bottom: 1px solid #1f1f1f;
}
@media (min-width: 1024px)
{
    .slick-arrow::before
    {
        width: 10px;
        height: 10px; 
        margin-top: -5px;
    }
}
.slick-arrow:hover::before
{
    border-color: #3539ed;
}

.slick-prev
{
    left: 22px;
}
@media (min-width: 1024px)
{
    .slick-prev
    {
        left: 34px;
    }
}
.slick-prev::before
{
    left: 2px;

    transform: rotate(45deg);

    border-left: 1px solid #1f1f1f;
}

.slick-next
{
    right: 22px;
}
@media (min-width: 1024px)
{
    .slick-next
    {
        right: 34px;
    }
}
.slick-next::before
{
    right: 2px;

    transform: rotate(-45deg);

    border-right: 1px solid #1f1f1f;
}

.slick-dots
{
    font-size: 0;
    line-height: 0;

    text-align: center;
}
.slick-dots li
{
    display: inline-block;

    margin: 0 7px;

    vertical-align: middle;
}
.slick-dots li.slick-active button
{
    border-color: #1f1f1f;
}
.slick-dots li.slick-active button::before
{
    opacity: 1;
}
.slick-dots button
{
    position: relative;
    z-index: 1;

    width: 20px;
    height: 20px;
    padding: 0;

    cursor: pointer;
    transition: all .3s ease-in-out; 

    border: 2px solid #d3d3d3;
    border-radius: 50%;
    background: transparent;
}
@media (min-width: 1024px)
{
    .slick-dots button
    {
        width: 17px;
        height: 17px;
    }
}
.slick-dots button:hover
{
    border-color: #3539ed;
}
.slick-dots button::before
{
    position: absolute;
    top: 50%;
    left: 50%;

    width: 8px;
    height: 8px;
    margin: -4px 0 0 -4px;

    content: '';

    opacity: 0; 
    border-radius: 50%;
    background-color: #1f1f1f;
}
@media (min-width: 1024px)
{
    .slick-dots button::before
    {
        width: 7px;
        height: 7px; 
        margin: -3.5px 0 0 -3.5px;
    }
}

.about
{
    margin-top: 10px;
    padding-bottom: 20px;
}
@media (min-width: 640px)
{
    .about
    {
        margin-top: 0;
        padding-bottom: 21px;
    }
}
@media (min-width: 767px)
{
    .about
    {
        padding-bottom: 20px;
    }
}
@media (min-width: 1024px)
{
    .about
    {
        padding-top: 33px;
        padding-bottom: 28px;
    }
}
.about__holder
{
    margin: 0 -10px;
    padding: 28px 10px 18px;

    text-align: center;

    border-top: 1px solid #e3e3e3; 
    background-color: #f5f5f5;
}
@media (min-width: 640px)
{
    .about__holder
    {
        margin: 0 -20px; 
        padding: 39px 20px 18px;
    }
}
@media (min-width: 767px)
{
    .about__holder
    {
        margin: 0;
        padding: 31px 20px 17px; 

        border: 1px solid #e3e3e3;
    }
}
@media (min-width: 1024px)
{
    .about__holder
    {
        padding: 40px 55px 51px;
    }
}
.about__title
{
    font-size: 20px;
    font-weight: 700;

    display: block;

    padding-bottom: 21px;
}
@media (min-width: 640px)
{
    .about__title
    {
        font-size: 26px;
        line-height: 28px;

        padding-bottom: 29px; 

        text-align: left;
    }
}
@media (min-width: 767px)
{
    .about__title
    {
        font-size: 32px;
        line-height: 38px;

        padding-bottom: 33px;
    }
}
@media (min-width: 1024px)
{
    .about__title
    {
        font-size: 42px;
        line-height: 52px;

        padding-bottom: 46px;
    }
}
@media (min-width: 1266px)
{
    .about__row
    {
        display: flex;
        flex-direction: row;

        margin: 0 -11px; 

        flex-wrap: wrap;
        align-items: stretch;
    }
}
.about__col
{
    margin-bottom: 14px;
}
@media (min-width: 640px)
{
    .about__col
    {
        margin-bottom: 21px;
    }
}
@media (min-width: 767px)
{
    .about__col
    {
        margin-bottom: 18px;
    }
}
@media (min-width: 1266px)
{
    .about__col
    {
        display: flex;

        width: 50%;
        padding: 0 11px;
    }
}
.about__item
{
    padding: 47px 20px 37px; 

    border: 1px solid #e3e3e3;
    background-color: #fff;
}
@media (min-width: 640px)
{
    .about__item
    {
        padding: 47px 20px 33px;
    }
}
@media (min-width: 767px)
{
    .about__item
    {
        font-size: 0;
        line-height: 0;

        padding: 47px 32px 31px;
    }
}
@media (min-width: 1266px)
{
    .about__item
    {
        display: flex;
        flex-direction: column;

        width: 100%; 
        padding: 47px 42px 51px;
    }
}
.about__item-icon
{
    width: 73px;
    height: 75px;
    margin: 0 auto 37px;
}
@media (min-width: 767px)
{
    .about__item-icon
    {
        font-size: 16px;
        line-height: 1.2;

        display: inline-block;

        vertical-align: top;
    }
}
@media (min-width: 1266px)
{
    .about__item-icon
    {
        display: block;

        margin: 0 auto 42px;
    }
}
.about__item-icon img
{
    display: block;

    width: 100%;
}
@media (min-width: 767px)
{
    .about__item-info
    {
        font-size: 16px;
        line-height: 1.2;

        display: inline-block;

        width: calc(100% - 73px);
        margin-top: -5px; 
        padding-left: 31px;

        text-align: left;
        vertical-align: top;
    }
}
@media (min-width: 1266px)
{
    .about__item-info
    {
        display: block;

        width: 100%;
        padding-left: 0;
    }
}
.about__item-name
{
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;

    display: block;

    padding-bottom: 10px; 

    color: #3539ed;
}
.about__item-descr
{
    font-size: 15px;
    line-height: 21px;
}
@media (min-width: 1024px)
{
    .about__item-descr
    {
        letter-spacing: -.2px;
    }
}

.article-desc
{
    padding-top: 9px;
    padding-bottom: 15px;
}
@media (min-width: 640px)
{
    .article-desc
    {
        padding-top: 42px;
        padding-bottom: 38px;
    }
}
@media (min-width: 767px)
{
    .article-desc
    {
        margin-bottom: 66px;
        padding-top: 30px;
        padding-bottom: 10px;

        border: 1px solid #e3e3e3;
        background-color: #f5f5f5;
    }
}
@media (min-width: 1024px)
{
    .article-desc
    {
        margin-top: 30px;
        margin-bottom: 80px;
        padding: 45px 0 64px;
    }
}
.article-desc__title
{
    display: none;
}
@media (min-width: 640px)
{
    .article-desc__title
    {
        font-size: 32px;
        font-weight: 700;
        line-height: 1.19; 

        display: block;

        margin-bottom: 20px;

        color: #1f1f1f;
    }
}
@media (min-width: 767px)
{
    .article-desc__title
    {
        margin-bottom: 23px;
        padding: 0 21px;
    }
}
@media (min-width: 1024px)
{
    .article-desc__title
    {
        font-size: 42px;
        line-height: 1.24; 

        margin-bottom: 42px;
        padding: 0 54px;
    }
}
.article-desc__header
{
    position: relative;
    z-index: 1; 

    margin: 0 -10px 33px;
}
@media (min-width: 640px)
{
    .article-desc__header
    {
        margin: 0 -20px 36px;
    }
}
@media (min-width: 767px)
{
    .article-desc__header
    {
        margin: 0 0 34px;
    }
}
@media (min-width: 1024px)
{
    .article-desc__header
    {
        margin-bottom: 64px;
    }
}
.article-desc__time
{
    position: absolute;
    top: 19px;
    left: 21px;

    display: none; 

    width: auto;
}
@media (min-width: 640px)
{
    .article-desc__time
    {
        display: block;
    }
}
@media (min-width: 1024px)
{
    .article-desc__time
    {
        display: none;
    }
}
.article-desc__img
{
    display: block;

    width: 100%;
    height: 238px;

    object-fit: cover;
}
@media (min-width: 640px)
{
    .article-desc__img
    {
        height: 360px;
    }
}
@media (min-width: 767px)
{
    .article-desc__img
    {
        height: 355px;
    }
}
@media (min-width: 1024px)
{
    .article-desc__img
    {
        height: 480px;
    }
}
@media (min-width: 640px)
{
    .article-desc__body
    {
        margin-bottom: 37px;
    }
}
@media (min-width: 767px)
{
    .article-desc__body
    {
        margin-bottom: 48px;
        padding-right: 20px; 
        padding-left: 20px;
    }
}
@media (min-width: 1024px)
{
    .article-desc__body
    {
        margin-bottom: 61px;
        padding-right: 50px; 
        padding-left: 50px;
    }
}
@media (min-width: 640px)
{
    .article-desc .static-content p
    {
        padding-right: 20px; 
        padding-left: 20px;
    }
}
@media (min-width: 767px)
{
    .article-desc .static-content p
    {
        padding-right: 0; 
        padding-left: 0;
    }
}
@media (min-width: 1024px)
{
    .article-desc .static-content p
    {
        max-width: 760px; 
        margin-right: auto;
        margin-left: auto;
    }
}
.article-desc .static-content img
{
    width: 100%;
    height: auto;
}
@media (min-width: 640px)
{
    .article-desc__footer
    {
        position: relative;
        z-index: 1;

        text-align: center;
    }
}
@media (min-width: 767px)
{
    .article-desc__footer
    {
        margin-right: 22px; 
        margin-left: 22px;
    }
}
@media (min-width: 1024px)
{
    .article-desc__footer
    {
        margin-right: 52px; 
        margin-left: 52px;
    }
}
@media (min-width: 640px)
{
    .article-desc__footer::before
    {
        position: absolute;
        z-index: -1; 
        top: 50%;
        right: 0;
        left: 0;

        content: '';

        border-bottom: 1px solid #ccc;
    }
}
@media (min-width: 640px)
{
    .article-desc__footer-holder
    {
        display: inline-block;

        padding: 0 31px;

        text-align: center; 
        vertical-align: middle;

        background-color: #fff;
    }
}
@media (min-width: 767px)
{
    .article-desc__footer-holder
    {
        background-color: #f5f5f5;
    }
}
@media (min-width: 640px)
{
    .article-desc__footer-decor
    {
        display: inline-block;

        width: 12px;
        height: 12px;
        margin: 0 7px;

        vertical-align: middle;

        border: 4px solid #ccc;
        background-color: #fff;
    }
}
@media (min-width: 767px)
{
    .article-desc__base
    {
        padding: 43px 20px 0;
    }
}
@media (min-width: 1024px)
{
    .article-desc__base
    {
        padding: 48px 53px 0;
    }
}
.article-desc__base .base
{
    background-color: #fff;
}

.articles-cards
{
    padding-top: 33px;
    padding-bottom: 30px;
}
@media (min-width: 640px)
{
    .articles-cards
    {
        padding-top: 65px;
    }
}
@media (min-width: 767px)
{
    .articles-cards
    {
        padding-top: 26px;
    }
}
@media (min-width: 1024px)
{
    .articles-cards
    {
        padding-top: 23px;
    }
}
.articles-cards--categories
{
    padding-top: 12px;
    padding-bottom: 48px;
}
@media (min-width: 640px)
{
    .articles-cards--categories
    {
        padding-top: 21px;
        padding-bottom: 57px;
    }
}
@media (min-width: 767px)
{
    .articles-cards--categories
    {
        padding-top: 16px;
        padding-bottom: 77px;
    }
}
@media (min-width: 767px)
{
    .articles-cards--categories .articles-cards__list
    {
        margin-bottom: 4px;
    }
}
@media (min-width: 1024px)
{
    .articles-cards--categories .articles-cards__list
    {
        margin-bottom: 16px;
    }
}
.articles-cards--categories .articles-cards__item
{
    margin-bottom: 30px;
}
@media (min-width: 767px)
{
    .articles-cards--categories .articles-cards__item
    {
        width: 33.33%; 
        margin-bottom: 39px;
    }
}
@media (min-width: 1024px)
{
    .articles-cards--categories .articles-cards__item
    {
        margin-bottom: 37px;
    }
}
@media (min-width: 1100px)
{
    .articles-cards--categories .articles-cards__item
    {
        width: 25%;
    }
}
.articles-cards--main
{
    position: relative;
    z-index: 1; 

    padding-top: 12px;
    padding-bottom: 48px;
}
.articles-cards--main::before
{
    position: absolute;
    z-index: -1;
    top: 0;
    right: -10px;
    bottom: 0;
    left: -10px;

    content: '';

    background-color: #f5f5f5;
}
@media (min-width: 767px)
{
    .articles-cards--main::before
    {
        display: none;
    }
}
@media (min-width: 640px)
{
    .articles-cards--main
    {
        padding-top: 21px;
        padding-bottom: 57px;
    }
}
@media (min-width: 767px)
{
    .articles-cards--main
    {
        padding-top: 10px;
        padding-bottom: 77px;
    }
}
@media (min-width: 767px)
{
    .articles-cards--main .articles-cards__list
    {
        margin-bottom: 4px;
    }
}
@media (min-width: 1024px)
{
    .articles-cards--main .articles-cards__list
    {
        margin-bottom: 16px;
    }
}
.articles-cards--main .articles-cards__item
{
    margin-bottom: 30px;
}
@media (min-width: 767px)
{
    .articles-cards--main .articles-cards__item
    {
        margin-bottom: 28px;
    }
}
@media (min-width: 1024px)
{
    .articles-cards--main .articles-cards__item
    {
        margin-bottom: 37px;
    }
}
@media (min-width: 640px)
{
    .articles-cards--day-news
    {
        padding-top: 21px;
        padding-bottom: 0;
    }
}
@media (min-width: 767px)
{
    .articles-cards--day-news
    {
        padding-top: 10px;
        padding-bottom: 0;
    }
}
@media (min-width: 767px)
{
    .articles-cards--day-news .articles-cards__list
    {
        margin-bottom: 0;
    }
}
@media (min-width: 1024px)
{
    .articles-cards--day-news .articles-cards__list
    {
        margin-bottom: 0;
    }
}
.articles-cards--day-news .articles-cards__item
{
    margin-bottom: 0;
}
@media (min-width: 767px)
{
    .articles-cards--day-news .articles-cards__item
    {
        margin-bottom: 0;
    }
}
@media (min-width: 1024px)
{
    .articles-cards--day-news .articles-cards__item
    {
        margin-bottom: 0;
    }
}
.articles-cards--sidebar
{
    display: none;
}
@media (min-width: 767px)
{
    .articles-cards--sidebar
    {
        display: block;
    }
    .articles-cards--sidebar .articles-cards__list
    {
        margin-right: 0; 
        margin-left: 0;
    }
    .articles-cards--sidebar .articles-cards__item
    {
        display: block;

        width: auto; 
        padding: 0;
    }
}
.articles-cards__title
{
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4; 

    display: block;

    margin-bottom: 32px;

    color: #1f1f1f;
}
@media (min-width: 640px)
{
    .articles-cards__title
    {
        font-size: 26px;
        line-height: 1.08;
    }
}
@media (min-width: 767px)
{
    .articles-cards__title
    {
        line-height: .92; 

        margin-bottom: 31px;
    }
}
@media (min-width: 1024px)
{
    .articles-cards__title
    {
        font-size: 32px;
        line-height: .75; 

        margin-bottom: 46px;
    }
}
@media (min-width: 640px)
{
    .articles-cards__list
    {
        font-size: 0;
        line-height: 0; 

        margin: 0 -16px;
    }
}
@media (min-width: 767px)
{
    .articles-cards__list
    {
        margin-bottom: 44px;
    }
}
@media (min-width: 1024px)
{
    .articles-cards__list
    {
        margin-bottom: 66px;
    }
}
.articles-cards__item
{
    margin-bottom: 37px;
}
@media (min-width: 640px)
{
    .articles-cards__item
    {
        font-size: 16px;
        line-height: 1.2; 

        display: inline-block;

        width: 50%;
        padding: 0 16px;

        vertical-align: top;
    }
}
@media (min-width: 767px)
{
    .articles-cards__item
    {
        margin-bottom: 29px;
    }
}
@media (min-width: 1024px)
{
    .articles-cards__item
    {
        margin-bottom: 42px;
    }
}
@media (min-width: 1150px)
{
    .articles-cards__item
    {
        width: 33.33%;
    }
}
.articles-cards__bottom
{
    padding: 0 9px;
}
.articles-cards__btn
{
    max-width: 220px; 
    margin: 0 auto;
    padding-right: 14px;
    padding-left: 14px;
}

.articles-top
{
    margin: 0 -10px;
    padding: 9px 10px 0;

    border-bottom: 1px solid #f5f5f5;
}
@media (min-width: 767px)
{
    .articles-top
    {
        border-bottom: 0;
    }
}
@media (min-width: 1024px)
{
    .articles-top
    {
        font-size: 0;
        line-height: 0; 

        margin: 0 -15px;
        padding: 0 0 32px;
    }
}
.articles-top__col
{
    margin-bottom: 33px;
}
@media (min-width: 640px)
{
    .articles-top__col
    {
        margin-bottom: 66px;
    }
}
@media (min-width: 767px)
{
    .articles-top__col
    {
        margin-bottom: 57px;
    }
}
@media (min-width: 1024px)
{
    .articles-top__col
    {
        display: inline-block;

        width: 50%; 
        margin-bottom: 0;
        padding: 0 15px;

        vertical-align: top;
    }
}

.base-block
{
    margin: 0 -10px;
    padding-top: 9px;
}
@media (min-width: 640px)
{
    .base-block
    {
        margin: 0 -20px;
    }
}
@media (min-width: 767px)
{
    .base-block
    {
        margin: 0; 
        padding-top: 0;
    }
    .base-block .base
    {
        display: none;
    }
}
@media (min-width: 1266px)
{
    .base-block
    {
        padding-top: 30px;
    }
}
.base-block__hero
{
    position: relative;
    z-index: 0;

    padding: 30px 17px 39px;

    background-position: center; 
    background-size: cover;
}
@media (min-width: 640px)
{
    .base-block__hero
    {
        font-size: 0;
        line-height: 0;

        padding: 36px 36px 39px;
    }
}
@media (min-width: 767px)
{
    .base-block__hero
    {
        padding: 31px 36px 69px;
    }
}
@media (min-width: 1266px)
{
    .base-block__hero
    {
        padding: 61px 44px 55px;
    }
}
.base-block__hero:before
{
    position: absolute;
    z-index: -1; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    background-color: rgba(44, 45, 77, .75);
}
.base-block__hero-img
{
    margin-bottom: 17px;
}
@media (min-width: 640px)
{
    .base-block__hero-img
    {
        font-size: 16px;
        line-height: 1.2;

        display: inline-block;

        width: 255px;
        margin: 15px 0 0; 

        vertical-align: top;
    }
}
@media (min-width: 767px)
{
    .base-block__hero-img
    {
        display: block;

        width: 100%;
        margin: 0 0 22px;
    }
}
@media (min-width: 1266px)
{
    .base-block__hero-img
    {
        font-size: 16px;
        line-height: 1.2;

        display: inline-block;

        width: 260px;
        margin: 0; 

        vertical-align: top;
    }
}
.base-block__hero-img img
{
    display: block;

    width: 225px;
    margin: 0 auto;
}
@media (min-width: 767px)
{
    .base-block__hero-img img
    {
        width: 210px;
    }
}
@media (min-width: 1266px)
{
    .base-block__hero-img img
    {
        width: 260px;
    }
}
.base-block__hero-content
{
    text-align: center;
}
@media (min-width: 640px)
{
    .base-block__hero-content
    {
        font-size: 16px;
        line-height: 1.2;

        display: inline-block;

        width: calc(100% - 255px);
        padding-left: 47px;

        text-align: left; 
        vertical-align: top;
    }
}
@media (min-width: 767px)
{
    .base-block__hero-content
    {
        display: block;

        width: 100%;
        padding-left: 0;

        text-align: center;
    }
}
@media (min-width: 1266px)
{
    .base-block__hero-content
    {
        font-size: 16px;
        line-height: 1.2;

        display: inline-block;

        width: calc(100% - 260px);
        padding: 30px 54px 0 37px; 

        text-align: left;
        vertical-align: top;
    }
}
.base-block__hero-title
{
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;

    display: block;

    color: #fff;
}
@media (min-width: 640px)
{
    .base-block__hero-title
    {
        font-size: 32px;
        line-height: 38px;

        padding-bottom: 22px;
    }
}
@media (min-width: 767px)
{
    .base-block__hero-title
    {
        padding-bottom: 9px;
    }
}
@media (min-width: 1024px)
{
    .base-block__hero-title
    {
        font-size: 42px;
        line-height: 52px;

        padding-bottom: 22px;
    }
}
.base-block__hero-subtitle
{
    font-size: 16px;
    font-weight: 700;
    line-height: 42px;

    display: block;

    padding-bottom: 21px; 

    color: #fff;
}
@media (min-width: 640px)
{
    .base-block__hero-subtitle
    {
        font-size: 26px;
        line-height: 42px;

        padding-bottom: 26px;
    }
}
@media (min-width: 767px)
{
    .base-block__hero-subtitle
    {
        padding-bottom: 27px;
    }
}
@media (min-width: 1024px)
{
    .base-block__hero-subtitle
    {
        font-size: 32px;
        line-height: 42px;

        padding-bottom: 65px;
    }
}
.base-block__hero-ctrl
{
    font-size: 0;
    line-height: 0;

    margin: 0 -7px;
    padding: 0 9px;
}
@media (min-width: 640px)
{
    .base-block__hero-ctrl
    {
        max-width: 294px; 
        margin: 0 -10px;
        padding: 0;
    }
}
@media (min-width: 767px)
{
    .base-block__hero-ctrl
    {
        margin: 0 auto;
    }
}
@media (min-width: 1266px)
{
    .base-block__hero-ctrl
    {
        max-width: 282px;
        margin: 0;
        margin-left: auto;
    }
}
.base-block__hero-demo
{
    font-size: 16px;
    line-height: 1.2;

    display: inline-block;

    width: calc(100% - 114px); 
    padding: 0 7px;

    vertical-align: top;
}
@media (min-width: 640px)
{
    .base-block__hero-demo
    {
        width: calc(100% - 120px); 
        padding: 0 10px;
    }
}
@media (min-width: 767px)
{
    .base-block__hero-demo
    {
        padding: 0 7px;
    }
}
.base-block__hero-demo .btn
{
    padding: 16px 10px 16px;
}
.base-block__hero-buy
{
    font-size: 16px;
    line-height: 1.2;

    display: inline-block;

    width: 114px; 
    padding: 0 7px;

    vertical-align: top;
}
@media (min-width: 640px)
{
    .base-block__hero-buy
    {
        width: 120px;
        padding: 0 10px;
    }
}
@media (min-width: 767px)
{
    .base-block__hero-buy
    {
        width: 114px; 
        padding: 0 7px;
    }
}
.base-block__hero-buy .btn
{
    padding: 16px 10px 16px;
}
.base-block__content
{
    margin-bottom: 37px; 
    padding: 39px 10px 13px;

    border: 1px solid #e3e3e3;
    border-width: 0 0 1px;
    background-color: #f5f5f5;
}
@media (min-width: 640px)
{
    .base-block__content
    {
        margin-bottom: 62px; 
        padding: 36px 20px 20px;
    }
}
@media (min-width: 767px)
{
    .base-block__content
    {
        margin-bottom: 67px; 
        padding: 37px 24px 15px;
    }
}
@media (min-width: 1024px)
{
    .base-block__content
    {
        border-width: 0 1px 1px;
    }
}
@media (min-width: 1266px)
{
    .base-block__content
    {
        margin-bottom: 71px;
        padding: 69px 24px 61px;
    }
    .base-block__content .static-content
    {
        max-width: 760px;
        margin: 0 auto;
    }
}
.base-block__holder
{
    padding: 0 10px; 

    text-align: center;
}
@media (min-width: 640px)
{
    .base-block__holder
    {
        padding: 0 20px 41px; 

        text-align: left;
    }
}
@media (min-width: 767px)
{
    .base-block__holder
    {
        padding: 0 0 36px;

        text-align: center;
    }
}
@media (min-width: 1024px)
{
    .base-block__holder
    {
        padding: 0 0 73px;
    }
}
.base-block__holder_list
{
    padding: 0 10px; 

    text-align: left;
}
@media (min-width: 640px)
{
    .base-block__holder_list
    {
        padding: 0 20px 41px; 

        text-align: left;
    }
}
@media (min-width: 767px)
{
    .base-block__holder_list
    {
        padding: 0 0 36px;

        text-align: left;
    }
}
@media (min-width: 1024px)
{
    .base-block__holder_list
    {
        padding: 0 0 73px;
    }
}
.base-block__title
{
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;

    display: block;

    padding-bottom: 43px;
}
@media (min-width: 640px)
{
    .base-block__title
    {
        font-size: 26px;
        line-height: 32px;
    }
}
@media (min-width: 767px)
{
    .base-block__title
    {
        padding-bottom: 48px;

        text-align: left;
    }
}
@media (min-width: 1024px)
{
    .base-block__title
    {
        font-size: 32px;
        line-height: 24px;

        padding-bottom: 79px;
    }
}
.base-block__row
{
    font-size: 0;
    line-height: 0;

    margin: 0 -15px;
}
@media (min-width: 1024px)
{
    .base-block__row
    {
        margin: 0 -10px;
    }
}
.base-block__col
{
    padding-bottom: 62px;
}
@media (min-width: 640px)
{
    .base-block__col
    {
        font-size: 16px;
        line-height: 1.2;

        display: inline-block;

        width: 33.33%;
        padding: 0 15px 48px; 

        vertical-align: top;
    }
}
@media (min-width: 767px)
{
    .base-block__col
    {
        width: 50%;
        padding: 0 15px 55px;
    }
}
@media (min-width: 1024px)
{
    .base-block__col
    {
        width: 33.33%;
        padding: 0 10px 55px;
    }
}
.base-block__item
{
    display: inline-block;
}
@media (min-width: 640px)
{
    .base-block__item
    {
        text-align: center;
    }
}
.base-block__item:hover .base-block__item-name
{
    color: #3539ed;
}
.base-block__item-img
{
    display: block;

    max-width: 300px;
    margin: 0 auto 22px;
}
@media (min-width: 640px)
{
    .base-block__item-img
    {
        margin: 0 auto 13px;
    }
}
@media (min-width: 767px)
{
    .base-block__item-img
    {
        margin: 0 auto 24px;
    }
}
@media (min-width: 1024px)
{
    .base-block__item-img
    {
        margin: 0 auto 35px;
    }
}
.base-block__item-img img
{
    display: block;

    width: 185px; 
    margin: 0 auto;
}
@media (min-width: 640px)
{
    .base-block__item-img img
    {
        width: auto; 
        height: 210px;
    }
}
@media (min-width: 767px)
{
    .base-block__item-img img
    {
        height: 236px;
    }
}
@media (min-width: 1024px)
{
    .base-block__item-img img
    {
        height: 256px;
    }
}
.base-block__item-name
{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    display: inline-block;

    max-width: 300px;
    margin: 0 auto; 
    padding: 0 40px;

    transition: all .3s ease-in-out;

    color: #1f1f1f;
}
.base-block__item-name span
{
    display: inline-block;
}
@media (min-width: 640px)
{
    .base-block__item-name
    {
        padding: 0 9px;
    }
}
@media (min-width: 767px)
{
    .base-block__item-name
    {
        font-size: 18px;
        line-height: 24px;

        padding: 0 4px; 

        text-align: left;
    }
}
@media (min-width: 1024px)
{
    .base-block__item-name
    {
        padding: 0;
    }
}

.base-top
{
    position: relative;
    z-index: 2; 

    display: block;
}
@media (min-width: 767px)
{
    .base-top
    {
        overflow-y: hidden; 

        height: 0;
    }
}
.base-top__btn
{
    width: calc(100% + 20px); 
    margin: 0 -10px 8px;
    padding-top: 16px;
    padding-bottom: 15px;
}
@media (min-width: 640px)
{
    .base-top__btn
    {
        width: calc(100% + 40px); 
        margin-right: -20px;
        margin-left: -20px;
    }
}
.base-top__btn::before
{
    display: none;
}
.base-top__btn .icon-svg
{
    display: inline-block;

    margin-right: 14px;

    vertical-align: middle;

    fill: #fff;
}
.base-top__btn-txt
{
    font-size: 15px;
    font-weight: 700; 

    display: inline-block;

    vertical-align: middle;
}
.base-top__collapse
{
    position: absolute;
    z-index: 5;
    top: 0;
    right: -10px;
    left: -10px;

    visibility: hidden;

    transition: all .3s ease-in-out;
    transform: translateY(20%); 

    opacity: 0;
    background-color: #fff;
    box-shadow: 0 3px 0 #f34e2d;
}
@media (min-width: 640px)
{
    .base-top__collapse
    {
        right: -20px;
        left: -20px;

        padding: 0 20px 9px;

        box-shadow: none;
    }
}
.base-top__collapse.active
{
    visibility: visible;

    transform: translateY(0); 

    opacity: 1;
}
.base-top__close
{
    position: absolute;
    top: 31px;
    right: 28px;

    width: 14px;
    height: 14px;
}
.base-top__close::before,
.base-top__close::after
{
    position: absolute;
    top: 6px;
    left: -2px;

    width: 18px;

    content: '';

    border-bottom: 1px solid #1f1f1f;
}
.base-top__close::before
{
    transform: rotate(45deg);
}
.base-top__close::after
{
    transform: rotate(-45deg);
}
.base-top__search
{
    background-color: #fff;
}
@media (min-width: 640px)
{
    .base-top__search
    {
        display: none;
    }
}

.base
{
    padding: 40px 0 32px;
}
.base--mobile-hidden
{
    display: none;
}
@media (min-width: 767px)
{
    .base--mobile-hidden
    {
        display: block;
    }
}
@media (min-width: 640px)
{
    .base
    {
        margin-top: 21px;
        margin-bottom: 11px;
        padding: 36px 0 33px;

        border: 3px solid #f34e2d;
    }
}
@media (min-width: 767px)
{
    .base
    {
        margin-top: 0;
        margin-bottom: 40px;
    }
}
@media (min-width: 1024px)
{
    .base
    {
        margin-bottom: 21px;
        padding: 46px 0 45px;

        border-width: 4px;
    }
}
.base__title
{
    font-size: 15px;
    font-weight: 700;

    display: block;

    margin-bottom: 25px;

    text-align: center; 
    text-transform: uppercase;

    color: #f34e2d;
}
@media (min-width: 1024px)
{
    .base__title
    {
        margin-bottom: 42px;
    }
}
.base__title .icon-svg
{
    display: inline-block;

    margin-right: 15px;

    vertical-align: middle;

    fill: #f34e2d;
}
@media (min-width: 640px)
{
    .base__title .icon-svg
    {
        margin-right: 14px;
    }
}
.base__title-txt
{
    display: inline-block;

    vertical-align: middle;
}
.base__slider
{
    margin-bottom: 26px;
    padding: 0 9px;
}
@media (min-width: 640px)
{
    .base__slider
    {
        margin-bottom: 6px;
        padding: 0 14px;
    }
}
@media (min-width: 767px)
{
    .base__slider
    {
        margin-bottom: 11px;
    }
}
@media (min-width: 1024px)
{
    .base__slider
    {
        font-size: 0;
        line-height: 0; 

        margin-bottom: 23px;
        padding: 0 21px;
    }
}
@media (min-width: 1024px)
{
    .base__slider .slick-current .base__slide
    {
        border-right: 1px solid #d6d6d6;
    }
}
.base__slide
{
    padding: 0 10px;
}
@media (min-width: 640px)
{
    .base__slide
    {
        padding: 0 15px;
    }
}
@media (min-width: 1024px)
{
    .base__slide
    {
        padding: 0 25px;
    }
}
.base__slide-item
{
    margin-bottom: 17px;
}
@media (min-width: 640px)
{
    .base__slide-item
    {
        margin-bottom: 28px;
    }
}
@media (min-width: 767px)
{
    .base__slide-item
    {
        margin-bottom: 24px;
    }
}
@media (min-width: 1024px)
{
    .base__slide-item
    {
        margin-bottom: 20px;
    }
}
.base__slide-link
{
    font-size: 0;
    line-height: 0; 

    display: inline-block;

    vertical-align: top;
}
.base__slide-link .icon-svg
{
    display: inline-block;

    margin-right: 13px;

    vertical-align: top;

    fill: #bebebe;
}
@media (min-width: 767px)
{
    .base__slide-link .icon-svg
    {
        margin-right: 11px;
    }
}
.base__slide-txt
{
    font-size: 15px;
    line-height: 1.4; 

    display: inline-block;

    width: calc(100% - 26px);
    margin-top: -4px;

    vertical-align: top;

    color: #1f1f1f;
}
@media (min-width: 767px)
{
    .base__slide-txt
    {
        font-size: 17px;
        line-height: 1.24;

        width: calc(100% - 24px); 
        margin-top: -2px;
    }
}
@media (min-width: 1024px)
{
    .base__slide-txt
    {
        line-height: 1.41;
    }
}
.base__nav
{
    position: relative; 

    padding: 0 44px;
}
@media (min-width: 640px)
{
    .base__nav
    {
        max-width: 300px; 
        margin: 0 auto;
    }
}

.block-articles
{
    margin: 0 -10px;
    padding: 32px 10px 0;

    border-top: 1px solid #e3e3e3; 
    background-color: #f5f5f5;
}
@media (min-width: 640px)
{
    .block-articles
    {
        margin: 0;
        padding: 0;
        padding-bottom: 27px; 

        border-top: none;
        background-color: transparent;
    }
}
@media (min-width: 767px)
{
    .block-articles
    {
        padding-bottom: 35px;
    }
}
@media (min-width: 1024px)
{
    .block-articles
    {
        padding-bottom: 20px;
    }
}
.block-articles__title
{
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;

    display: block;

    padding-bottom: 32px;
}
@media (min-width: 640px)
{
    .block-articles__title
    {
        font-size: 26px;

        padding-bottom: 36px;
    }
}
@media (min-width: 767px)
{
    .block-articles__title
    {
        padding-bottom: 30px;
    }
}
@media (min-width: 1024px)
{
    .block-articles__title
    {
        font-size: 32px;
        line-height: 24px;

        padding-bottom: 50px;
    }
}
@media (min-width: 640px)
{
    .block-articles__row
    {
        font-size: 0;
        line-height: 0;

        margin: 0 -15px;
    }
}
.block-articles__col
{
    padding-bottom: 37px;
}
@media (min-width: 640px)
{
    .block-articles__col
    {
        font-size: 16px;
        line-height: 1.2;

        display: inline-block;

        width: 50%;
        padding: 0 15px 34px; 

        vertical-align: top;
    }
}
@media (min-width: 767px)
{
    .block-articles__col
    {
        width: 100%;
        padding: 0 15px 28px;
    }
    .block-articles__col .card__time
    {
        width: 40%;
        margin-bottom: 0;
    }
    .block-articles__col .card__info
    {
        width: 30%;
        padding-right: 0;
        padding-left: 5px;
    }
}
@media (min-width: 1024px)
{
    .block-articles__col
    {
        width: 50%;
    }
}
@media (min-width: 1266px)
{
    .block-articles__col
    {
        width: 33.33%;
        padding: 0 15px 63px;
    }
}
.block-articles__load
{
    width: 24px;
    height: 24px;
    margin: 0 auto 0;
    padding: 15px 0 74px;
}
.block-articles__load img
{
    animation: rotation 2s infinite linear;
}
@media (min-width: 640px)
{
    .block-articles__load
    {
        display: none;
    }
}

@keyframes rotation
{
    from
    {
        transform: rotate(0deg);
    }
    to
    {
        transform: rotate(359deg);
    }
}

.block-interest
{
    display: none;

    padding-bottom: 59px;
}
@media (min-width: 640px)
{
    .block-interest
    {
        display: block;
    }
}
@media (min-width: 767px)
{
    .block-interest
    {
        padding-bottom: 31px;
    }
}
@media (min-width: 1024px)
{
    .block-interest
    {
        margin-bottom: -6px; 
        padding-bottom: 0;
    }
}
.block-interest__title
{
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;

    display: block;

    padding-bottom: 50px;
}
@media (min-width: 767px)
{
    .block-interest__title
    {
        padding-bottom: 43px;
    }
}
@media (min-width: 1024px)
{
    .block-interest__title
    {
        font-size: 32px;
        line-height: 24px;

        padding-bottom: 61px;
    }
}
.block-interest__row
{
    font-size: 0;
    line-height: 0;

    margin: 0 -15px;
}
.block-interest__col
{
    font-size: 16px;
    line-height: 1.2;

    display: inline-block;

    width: 50%;
    padding: 0 15px 29px; 

    vertical-align: top;
}
@media (min-width: 767px)
{
    .block-interest__col
    {
        padding: 0 15px 25px;
    }
}
@media (min-width: 1266px)
{
    .block-interest__col
    {
        width: 33.33%;
        padding: 0 15px 66px;
    }
}
.block-interest__col:nth-child(5)
{
    display: none;
}
@media (min-width: 1024px)
{
    .block-interest__col:nth-child(5)
    {
        display: inline-block;
    }
}
.block-interest__col:nth-child(6)
{
    display: none;
}
@media (min-width: 1024px)
{
    .block-interest__col:nth-child(6)
    {
        display: inline-block;
    }
}

.breadcrumbs
{
    display: none;

    padding: 9px 0; 

    border-bottom: 1px solid #e3e3e3;
}
@media (min-width: 1024px)
{
    .breadcrumbs
    {
        display: block;
    }
}
.breadcrumbs ul
{
    font-size: 0;
    line-height: 0; 

    margin: 0 -22px;
}
.breadcrumbs li
{
    font-size: 15px;
    line-height: 1;

    position: relative; 

    display: inline-block;

    margin-bottom: 10px;
    padding: 0 22px;

    vertical-align: top;

    color: #000;
}
.breadcrumbs li:before
{
    position: absolute;
    top: 6px;
    right: -6px;

    width: 14px;
    height: 1px;

    content: '';

    background-color: #8a8a8a;
}
.breadcrumbs li:after
{
    position: absolute;
    top: 3px;
    right: -5px;

    width: 7px;
    height: 7px;

    content: '';
    transform: rotate(-45deg); 

    border-right: 1px solid #8a8a8a;
    border-bottom: 1px solid #8a8a8a;
}
.breadcrumbs li:last-child:before,
.breadcrumbs li:last-child:after
{
    display: none;
}
.breadcrumbs a
{
    line-height: .9;

    color: #3539ed;
    border-bottom: 1px solid transparent;
}
.breadcrumbs a:hover
{
    border-bottom-color: #3539ed;
}

@media (min-width: 1024px)
{
    .card--main .card__title
    {
        font-size: 18px;
        line-height: 1.33;
    }
}

.card--large .card__time
{
    position: absolute;
    top: 9px;
    left: 10px;

    width: auto;
}
@media (min-width: 640px)
{
    .card--large .card__time
    {
        top: 19px;
        left: 20px;
    }
}

.card--large .card__time .time__data
{
    font-family: 'Roboto';
    font-size: 11px;

    text-transform: uppercase;
}
@media (min-width: 1024px)
{
    .card--large .card__time .time__data
    {
        margin-bottom: 0;
    }
}

@media (min-width: 640px)
{
    .card--large .card__img
    {
        height: 360px; 

        object-fit: cover;
    }
}

@media (min-width: 767px)
{
    .card--large .card__img
    {
        height: 355px;
    }
}

.card--large .card__holder
{
    position: absolute;
    right: 10px;
    bottom: 7px; 
    left: 10px;
}
@media (min-width: 640px)
{
    .card--large .card__holder
    {
        right: 21px;
        bottom: 7px; 
        left: 21px;
    }
}
@media (min-width: 767px)
{
    .card--large .card__holder
    {
        right: 28px;
        bottom: 38px; 
        left: 28px;
    }
}

.card--large .card__info
{
    width: auto; 
    margin-right: 21px;
    padding-left: 0;

    color: #fff;
}
@media (min-width: 767px)
{
    .card--large .card__info
    {
        margin-right: 17px;
    }
}
.card--large .card__info .icon-svg
{
    fill: #fff;
}

.card--large .card__title
{
    font-size: 20px;
    line-height: 1.4; 

    margin-bottom: 12px;
}
@media (min-width: 640px)
{
    .card--large .card__title
    {
        font-size: 26px; 

        margin-bottom: 16px;
    }
}
@media (min-width: 767px)
{
    .card--large .card__title
    {
        font-size: 20px;
        line-height: 1.47; 

        margin-bottom: 18px;
    }
}

.card--large .card__desc
{
    margin-bottom: 15px;
}
@media (min-width: 640px)
{
    .card--large .card__desc
    {
        margin-bottom: 17px;
    }
}
@media (min-width: 767px)
{
    .card--large .card__desc
    {
        font-size: 17px; 

        margin-bottom: 22px;
    }
}
@media (min-width: 1024px)
{
    .card--large .card__desc
    {
        line-height: 1.48; 

        margin-bottom: 24px;
    }
}
.card--large .card__desc .js-shave-char
{
    font-size: 17px;
    font-weight: 700;
    line-height: 10px;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin-left: 8px;

    text-align: center; 
    vertical-align: middle;

    color: #fff;
    background-color: #3539ed;
}

.card--new-item .card__time.new-item
{
    position: absolute;
    top: 9px;
    left: 10px;

    width: auto;
}
@media (min-width: 640px)
{
    .card--new-item .card__time.new-item
    {
        top: 19px;
        left: 20px;
    }
}
.card--new-item .card__time.new-item time
{
    height: 40px;
}

.card--new-item .card__time .time__data.new-item
{
    font-family: 'Roboto';
    font-size: 11px;

    text-transform: uppercase;
}
@media (min-width: 1024px)
{
    .card--new-item .card__time .time__data.new-item
    {
        margin-bottom: 0;
    }
}

.card--new-item .card__time .time__txt.new-item
{
    display: none;
}

.card__top
{
    position: relative;
    z-index: 1; 

    margin: 0 -10px 22px;
}
@media (min-width: 640px)
{
    .card__top
    {
        margin: 0 -20px 23px;
    }
}
@media (min-width: 767px)
{
    .card__top
    {
        margin: 0 0 34px;
    }
}

.card__link
{
    position: relative;
    z-index: 1; 

    display: block;

    margin-bottom: 22px;
}
@media (min-width: 640px)
{
    .card__link
    {
        margin-bottom: 25px;
    }
}
@media (min-width: 767px)
{
    .card__link
    {
        margin-bottom: 21px;
    }
}
@media (min-width: 1024px)
{
    .card__link
    {
        margin-bottom: 15px;
    }
}
@media (min-width: 1024px)
{
    .card__link::before
    {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        content: '';
        transition: all .3s ease; 

        opacity: 0;
        background-color: rgba(53, 57, 237, .5);
    }
}
.card__link:hover::before
{
    opacity: 1;
}

.card__img
{
    display: block;

    width: 100%;
    height: auto;
}

.card__holder
{
    font-size: 0;
    line-height: 0; 

    margin-bottom: 12px;
}
@media (min-width: 767px)
{
    .card__holder
    {
        margin-bottom: 14px;
    }
}
@media (min-width: 1024px)
{
    .card__holder
    {
        margin-bottom: 15px;
    }
}

.card__time
{
    font-family: 'Oswald';
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;

    display: inline-block;

    width: 50%;
    padding-right: 5px;

    vertical-align: middle;
    letter-spacing: .56px; 

    color: #8a8a8a;
}
@media (min-width: 767px)
{
    .card__time
    {
        width: 100%; 
        margin-bottom: 10px;
    }
}
@media (min-width: 1024px)
{
    .card__time
    {
        width: 40%; 
        margin-bottom: 0;
    }
}

.card__info
{
    font-family: 'Oswald';
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;

    display: inline-block;

    width: 25%;
    padding-left: 5px;

    vertical-align: middle;
    letter-spacing: .56px; 

    color: #8a8a8a;
}
@media (min-width: 767px)
{
    .card__info
    {
        width: 50%; 
        padding-right: 5px;
        padding-left: 0;
    }
}
@media (min-width: 1024px)
{
    .card__info
    {
        width: 30%; 
        padding-right: 0;
        padding-left: 5px;
    }
}
.card__info .icon-svg
{
    display: inline-block;

    margin-top: -4px;
    margin-right: 5px;

    vertical-align: middle;

    fill: #8a8a8a;
}
.card__info-txt
{
    line-height: 1.3;

    display: inline-block;

    width: calc(100% - 25px); 

    vertical-align: middle;
}

.card__topic
{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;

    display: block;

    margin-bottom: 9px;

    text-transform: uppercase; 

    color: #8a8a8a;
}

.card__title
{
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5; 

    display: block;

    margin-bottom: 11px;

    color: #1f1f1f;
}
@media (min-width: 767px)
{
    .card__title
    {
        font-size: 15px;
        line-height: 1.4; 

        margin-bottom: 13px;
    }
}
@media (min-width: 1024px)
{
    .card__title
    {
        margin-bottom: 14px;
    }
}
.card__title:hover
{
    color: #3539ed;
}

.card__desc
{
    font-size: 15px;
    font-weight: 300;
    line-height: 1.4; 

    display: block;

    color: #1f1f1f;
}

.card__more
{
    font-size: 14px;
    font-weight: 400;

    text-transform: uppercase;
}
.card__more:hover
{
    color: #1f1f1f;
}
.card__more:hover .card__more-icon,
.card__more:hover .card__more-icon::before,
.card__more:hover .card__more-icon::after
{
    border-color: #1f1f1f;
}
.card__more-icon
{
    position: relative;
    z-index: 1;

    display: inline-block;

    width: 16px;
    height: 16px;
    margin-right: 11px;

    transition: all .3s ease-in-out; 
    vertical-align: middle;

    border: 2px solid #3539ed;
    border-radius: 50%;
}
.card__more-icon::before
{
    position: absolute;
    top: 50%;
    right: 3px;

    width: 6px;
    height: 6px;
    margin-top: -3px;

    content: '';
    transition: all .3s ease-in-out; 
    transform: rotate(-45deg);

    border-right: 2px solid #3539ed;
    border-bottom: 2px solid #3539ed;
}
.card__more-icon::after
{
    position: absolute;
    top: 50%;
    right: 4px;

    width: 6px;
    margin-top: -1px;

    content: '';
    transition: all .3s ease-in-out; 

    border-bottom: 2px solid #3539ed;
}
.card__more-txt
{
    display: inline-block;

    vertical-align: middle;
}

@media screen and (-webkit-min-device-pixel-ratio: 0)
{
    .card--large .card__desc .js-shave-char
    {
        line-height: 13px;
    }
}

.contacts
{
    padding-bottom: 20px;
}
@media (min-width: 640px)
{
    .contacts
    {
        padding-bottom: 0;
    }
}
@media (min-width: 1024px)
{
    .contacts
    {
        padding-bottom: 199px;
    }
}
.contacts__holder
{
    margin: 0 -10px; 
    padding: 48px 10px 37px;

    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    background-color: #f5f5f5;
}
@media (min-width: 640px)
{
    .contacts__holder
    {
        margin: 0 -20px; 
        padding: 33px 20px 44px;

        border-bottom: none;
    }
}
@media (min-width: 767px)
{
    .contacts__holder
    {
        margin: 0;
        padding: 42px 26px 44px; 

        border: 1px solid #e3e3e3;
    }
}
@media (min-width: 1024px)
{
    .contacts__holder
    {
        padding: 51px 55px 55px;
    }
}
.contacts__title
{
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;

    display: block;

    padding-bottom: 36px; 

    text-align: center;
}
@media (min-width: 640px)
{
    .contacts__title
    {
        padding-bottom: 31px; 

        text-align: left;
    }
}
@media (min-width: 767px)
{
    .contacts__title
    {
        font-size: 26px;
        line-height: 32px;

        padding-bottom: 33px;
    }
}
@media (min-width: 1024px)
{
    .contacts__title
    {
        font-size: 32px;
        line-height: 42px;

        padding-bottom: 35px;
    }
}
.contacts__item
{
    font-size: 0;
    line-height: 0;
}
@media (min-width: 767px)
{
    .contacts__item
    {
        padding-left: 4px;
    }
}
.contacts__item-icon
{
    font-size: 16px;
    line-height: 1.2;

    display: inline-block;

    width: 24px;
    height: 24px;
    margin-top: 3px; 

    vertical-align: top;
}
@media (min-width: 767px)
{
    .contacts__item-icon
    {
        margin-top: 5px;
    }
}
.contacts__item-icon svg
{
    fill: #f34e2d;
}
.contacts__item-info
{
    font-size: 16px;
    font-size: 15px;
    line-height: 1.2;
    line-height: 21px; 

    display: inline-block;

    width: calc(100% - 24px);
    padding-left: 21px;

    vertical-align: top;
}
@media (min-width: 767px)
{
    .contacts__item-info
    {
        font-size: 17px;
        line-height: 27px;

        padding-left: 19px;
    }
}
.contacts__item-name
{
    font-size: 17px;
    font-weight: 700; 
    line-height: 27px;

    display: block;

    color: #f34e2d;
}
.contacts__item-phone
{
    font-size: 17px;
    line-height: 27px; 

    display: block;
}
.contacts__item-phone a
{
    color: #1f1f1f;
}
.contacts__item-phone a:hover
{
    color: #3539ed;
}
.contacts__item-mail
{
    font-size: 17px;
    line-height: 27px; 

    display: block;
}
.contacts__item-mail a
{
    border-bottom: 1px solid transparent;
}
.contacts__item-mail a:hover
{
    border-bottom-color: #3539ed;
}
.contacts__subtitle
{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    display: block;

    padding: 35px 0 25px; 

    text-align: center;
}
@media (min-width: 640px)
{
    .contacts__subtitle
    {
        padding: 47px 0 21px; 

        text-align: left;
    }
}
@media (min-width: 767px)
{
    .contacts__subtitle
    {
        font-size: 18px;
        line-height: 28px;

        padding: 43px 0 20px;
    }
}
@media (min-width: 1024px)
{
    .contacts__subtitle
    {
        padding: 67px 0 28px;
    }
}

.footer
{
    padding: 37px 0 30px;

    text-align: center; 

    background-color: #edeefc;
}
@media (min-width: 640px)
{
    .footer
    {
        padding: 32px 0 30px;

        text-align: left;
    }
}
@media (min-width: 767px)
{
    .footer
    {
        padding: 46px 0 22px;
    }
}
@media (min-width: 1024px)
{
    .footer
    {
        padding: 46px 0 42px;
    }
}
@media (min-width: 640px)
{
    .footer__outer
    {
        position: relative;
    }
}
@media (min-width: 767px)
{
    .footer__outer
    {
        max-width: 990px;
        margin-left: auto;
    }
}
.footer__social
{
    max-width: 86px;
    margin: 0 auto; 
    padding-bottom: 31px;

    text-align: center;
}
@media (min-width: 640px)
{
    .footer__social
    {
        position: absolute;
        top: 4px;
        right: 0;

        padding-bottom: 0;
    }
}
@media (min-width: 767px)
{
    .footer__social
    {
        top: 0;
    }
}
@media (min-width: 1024px)
{
    .footer__social
    {
        top: -12px;
    }
}
.footer__holder
{
    font-size: 0;
    line-height: 0;

    margin: 0 -10px;
}
.footer__nav
{
    padding-bottom: 22px;
}
@media (min-width: 640px)
{
    .footer__nav
    {
        display: inline-block;

        width: 36%;
        padding: 0 10px 20px; 

        vertical-align: top;
    }
}
@media (min-width: 767px)
{
    .footer__nav
    {
        display: block;

        width: 100%;
    }
}
.footer__nav-item
{
    padding-bottom: 14px;
}
@media (min-width: 767px)
{
    .footer__nav-item
    {
        font-size: 16px;
        line-height: 1.2;

        display: inline-block;

        padding-right: 24px;
        padding-bottom: 0; 

        vertical-align: top;
    }
    .footer__nav-item:last-child
    {
        padding-right: 0;
    }
}
@media (min-width: 1024px)
{
    .footer__nav-item
    {
        padding-right: 53px;
    }
}
.footer__nav-link
{
    font-size: 15px;
    line-height: 1.2;

    color: #1f1f1f;
}
@media (min-width: 767px)
{
    .footer__nav-link
    {
        font-size: 16px;
        font-weight: 700;
    }
}
.footer__nav-link:hover
{
    color: #3539ed;
}
@media (min-width: 640px)
{
    .footer__info
    {
        display: inline-block;

        width: 46%;
        padding: 0 10px; 

        vertical-align: top;
    }
}
@media (min-width: 767px)
{
    .footer__info
    {
        font-size: 0;
        line-height: 0; 

        display: block;

        width: 100%;
    }
}
.footer__info-item
{
    padding-bottom: 14px;
}
@media (min-width: 767px)
{
    .footer__info-item
    {
        font-size: 15px;
        line-height: 1.2;

        display: inline-block;

        padding-right: 63px; 

        vertical-align: top;
    }
    .footer__info-item:last-child
    {
        padding-right: 0;
    }
}
@media (min-width: 1024px)
{
    .footer__info-item
    {
        padding-right: 73px;
    }
}
.footer__info-link
{
    font-size: 15px;
    line-height: 1.2;

    color: #8688a8;
}
.footer__info-link:hover
{
    color: #3539ed;
}

.header
{
    margin: 0 -10px;

    background-color: #efefef;
    box-shadow: 0 1px 0 #e3e3e3;
}
@media (min-width: 640px)
{
    .header
    {
        margin-right: -20px;
    }
}
@media (min-width: 767px)
{
    .header
    {
        margin: 0;
    }
}
.header__wrap
{
    font-size: 0;
    line-height: 0; 

    padding-left: 10px;
}
@media (min-width: 640px)
{
    .header__wrap
    {
        padding-left: 20px;
    }
}
@media (min-width: 767px)
{
    .header__wrap
    {
        padding: 27px 0 18px;
    }
}
@media (min-width: 1024px)
{
    .header__wrap
    {
        padding: 17px 0 12px;
    }
}
.header__logo
{
    display: inline-block;

    width: calc(100% - 42px); 

    vertical-align: middle;
}
@media (min-width: 640px)
{
    .header__logo
    {
        width: 270px; 
        padding-right: 10px;
    }
}
@media (min-width: 767px)
{
    .header__logo
    {
        width: 50%;
        padding-right: 15px;

        vertical-align: top;
    }
}
@media (min-width: 1024px)
{
    .header__logo
    {
        width: 41%;
    }
}
.header__logo-link
{
    font-size: 0;
    line-height: 0; 

    display: block;
}
.header__logo-link:hover
{
    opacity: .75;
}
.header__logo-img
{
    display: inline-block;

    width: 37px;
    height: 37px; 
    margin-right: 15px;

    vertical-align: middle;
}
@media (min-width: 640px)
{
    .header__logo-img
    {
        margin-right: 14px;
    }
}
@media (min-width: 767px)
{
    .header__logo-img
    {
        margin-right: 19px;

        vertical-align: top;
    }
}
@media (min-width: 1024px)
{
    .header__logo-img
    {
        margin-right: 14px;

        vertical-align: middle;
    }
}
.header__logo-right
{
    font-size: 16px;
    line-height: 1.2; 

    display: inline-block;

    width: calc(100% - 52px);

    vertical-align: middle;
}
@media (min-width: 640px)
{
    .header__logo-right
    {
        width: calc(100% - 51px);
    }
}
@media (min-width: 767px)
{
    .header__logo-right
    {
        width: calc(100% - 56px); 
        padding-top: 8px;

        vertical-align: top;
    }
}
@media (min-width: 1024px)
{
    .header__logo-right
    {
        padding-top: 0;

        vertical-align: middle;
    }
}
.header__logo-name
{
    font-size: 20px;
    font-weight: 800;
    line-height: 1;

    display: block;

    margin-bottom: 5px;

    text-transform: uppercase; 

    color: #1f1f1f;
}
@media (min-width: 767px)
{
    .header__logo-name
    {
        margin-bottom: 8px;
    }
}
@media (min-width: 1024px)
{
    .header__logo-name
    {
        display: inline-block;

        margin-right: 16px;
        margin-bottom: 8px;

        vertical-align: middle;
    }
}
@media (min-width: 1260px)
{
    .header__logo-name
    {
        margin-bottom: 0;
    }
}
.header__logo-subname
{
    font-size: 15px;
    font-weight: 400;
    line-height: 1.33; 

    display: block;

    color: #1f1f1f;
}
@media (min-width: 767px)
{
    .header__logo-subname
    {
        font-size: 17px;
        line-height: 1.18;
    }
}
@media (min-width: 1024px)
{
    .header__logo-subname
    {
        font-size: 18px;
        line-height: 1.11; 

        display: inline-block;

        vertical-align: middle;
    }
}
.header__holder
{
    display: none;
}
@media (min-width: 640px)
{
    .header__holder
    {
        display: inline-block;

        width: calc(100% - 312px); 
        padding: 0 19px 0 30px;

        vertical-align: middle;
    }
}
@media (min-width: 767px)
{
    .header__holder
    {
        width: 50%; 
        padding-right: 0;
        padding-left: 15px;
    }
}
@media (min-width: 1024px)
{
    .header__holder
    {
        display: inline-flex;
        flex-direction: row-reverse;

        width: 59%; 
        padding-left: 19px;

        align-items: center;
    }
}
.header__search
{
    display: block;

    width: 100%;
}
@media (min-width: 1024px)
{
    .header__search
    {
        width: 50%; 
        padding-right: 15px;
    }
}
.header__info
{
    display: none;
}
@media (min-width: 767px)
{
    .header__info
    {
        display: block; 

        margin-bottom: 9px;
    }
}
@media (min-width: 1024px)
{
    .header__info
    {
        width: 50%; 
        margin-bottom: 0;
        padding-left: 15px;
    }
}
@media (min-width: 1300px)
{
    .header__info
    {
        padding-left: 41px;
    }
}
.header__info-inner
{
    display: inline-block;

    width: calc(100% - 97px); 

    vertical-align: middle;
}
.header__info-link
{
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;

    display: inline-block;

    cursor: pointer; 
    vertical-align: middle;
}
.header__info-link .icon-svg
{
    display: inline-block;

    margin-right: 12px;

    transition: all .3s ease-in-out; 
    vertical-align: middle;

    fill: #3539ed;
}
.header__info-link:hover
{
    color: #1f1f1f;
}
.header__info-link:hover .icon-svg
{
    fill: #1f1f1f;
}
.header__info-txt
{
    display: inline-block;

    width: calc(100% - 31px); 

    vertical-align: middle;
}
.header__social
{
    display: inline-block;

    width: 97px;

    text-align: right; 
    vertical-align: middle;
}
.header__btn
{
    position: relative;
    z-index: 1; 

    display: inline-block;

    width: 42px;
    height: 71px;

    vertical-align: middle;

    background-color: #3539ed;
}
@media (min-width: 767px)
{
    .header__btn
    {
        display: none;
    }
}
.header__btn::before,
.header__btn::after
{
    position: absolute;
    left: 10px;

    width: 22px;

    content: '';

    border-top: 3px solid #fff;
}
.header__btn::before
{
    top: 28px;
}
.header__btn::after
{
    bottom: 28px;
}
.header__btn-decor
{
    position: absolute;
    top: 34px;
    left: 10px;

    width: 22px;

    border-top: 3px solid #fff;
}

.icon-svg
{
    display: block;
}

.nav
{
    position: fixed;
    z-index: 5;
    top: 0;
    right: 100%;
    bottom: 0;
    left: -100%;

    overflow: hidden;
    overflow-y: auto; 

    padding: 54px 15px 25px;

    transition: all .5s ease;

    background-color: #3539ed;
}
@media (min-width: 767px)
{
    .nav
    {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;

        overflow: visible; 

        margin-bottom: 40px;
        padding: 26px 10px 31px 45px;

        border: 4px solid #3539ed;
        background-color: transparent;
    }
}
@media (min-width: 1024px)
{
    .nav
    {
        width: 309px;
    }
}
@media (min-width: 767px)
{
    .nav::before
    {
        position: absolute;
        right: 7px;
        bottom: -14px;
        left: 6px;

        height: 14px;

        content: '';

        border-right: 4px solid #3539ed;
        border-bottom: 4px solid #3539ed; 
        border-left: 4px solid #3539ed;
    }
}
.nav.show
{
    right: 0; 
    left: 0;
}
.nav__close
{
    position: absolute;
    top: 16px;
    right: 11px;

    width: 15px;
    height: 15px;
}
@media (min-width: 767px)
{
    .nav__close
    {
        display: none;
    }
}
.nav__close::before,
.nav__close::after
{
    position: absolute;
    top: 5px;
    left: -3px;

    width: 19px;

    content: '';

    border-bottom: 1px solid #fff;
}
.nav__close::before
{
    transform: rotate(45deg);
}
.nav__close::after
{
    transform: rotate(-45deg);
}
.nav__list
{
    max-width: 140px; 
    margin: 0 auto;
}
@media (min-width: 767px)
{
    .nav__list
    {
        max-width: 100%;
    }
}
.nav__item-link
{
    font-size: 15px;
    font-weight: 700;
    line-height: 2.8;

    text-transform: uppercase; 

    color: #fff;
    border-bottom: 2px solid #fff;
}
@media (min-width: 767px)
{
    .nav__item-link
    {
        color: #1f1f1f;
        border-bottom-color: #e3e3e3;
    }
}
@media (min-width: 767px)
{
    .nav__item-link:hover
    {
        border-bottom-color: transparent;
    }
}

#preloader
{
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden; 

    margin: 0 auto;

    background: #fff;
}
#preloader .preloader-wrapper
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden; 

    margin: auto;
}

.preloader-center
{
    position: absolute;
    top: 50%;
    left: 50%;

    width: auto;
    height: auto;

    transform: translate(-50%, -50%);
}

.tcon-loader--spinner360
{
    position: relative;

    display: block;

    width: 80px;
    height: 80px;

    animation: tcon-spin360 .675s linear infinite forwards;

    border-radius: 80%;
    background: #f1f1f1;
}
.tcon-loader--spinner360:before,
.tcon-loader--spinner360:after
{
    position: absolute; 

    display: block;

    content: '';
}
.tcon-loader--spinner360:before
{
    z-index: 1;
    top: 0;
    right: 0;

    width: 50%;
    height: 80px;

    border-radius: 0 90px 90px 0;
    background: #3539ed;
    background-image: linear-gradient(#f1f1f1, #3539ed);
}
.tcon-loader--spinner360:after
{
    z-index: 1;
    top: 50%;
    left: 50%;

    width: 70px;
    height: 70px;

    transform: translate(-50%, -50%);

    border-radius: 80%;
    background: #fff;
}

@keyframes tcon-spin360
{
    to
    {
        transform: rotate(360deg);
    }
}

.tcon-visuallyhidden
{
    position: absolute;

    overflow: hidden;
    clip: rect(0 0 0 0);

    width: 1px; 
    height: 1px;
    margin: -1px;
    padding: 0;

    border: 0;
}
.tcon-visuallyhidden:active,
.tcon-visuallyhidden:focus
{
    position: static;

    overflow: visible;
    clip: auto;

    width: auto; 
    height: auto;
    margin: 0;
}

.reviews
{
    margin: 0 -10px;
    padding: 65px 10px 29px;

    border-top: 1px solid #e3e3e3; 
    background-color: #f5f5f5;
}
@media (min-width: 640px)
{
    .reviews
    {
        margin: 0;
        margin-bottom: 54px; 
        padding: 39px 0 39px;

        border: 1px solid #e3e3e3;
    }
}
@media (min-width: 767px)
{
    .reviews
    {
        padding: 36px 0 45px;
    }
}
@media (min-width: 1024px)
{
    .reviews
    {
        padding: 67px 0 47px;
    }
}
@media (min-width: 1266px)
{
    .reviews
    {
        padding: 67px 27px 47px;
    }
}
.reviews__title
{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;

    display: block;

    padding-bottom: 56px;
}
@media (min-width: 640px)
{
    .reviews__title
    {
        font-size: 26px;

        padding: 0 30px 43px;
    }
}
@media (min-width: 767px)
{
    .reviews__title
    {
        padding: 0 24px 53px;
    }
}
.reviews__form
{
    padding-bottom: 41px;
}
@media (min-width: 640px)
{
    .reviews__form
    {
        padding: 0 30px 40px;
    }
}
@media (min-width: 767px)
{
    .reviews__form
    {
        padding: 0 24px 36px;
    }
}
@media (min-width: 1024px)
{
    .reviews__form
    {
        padding: 0 24px 22px;
    }
}
@media (min-width: 1024px)
{
    .reviews__form-head
    {
        font-size: 0;
        line-height: 0;

        margin: 0 -20px;
    }
}
.reviews__form-box
{
    display: block;

    padding-bottom: 17px;
}
@media (min-width: 1024px)
{
    .reviews__form-box
    {
        font-size: 16px;
        line-height: 1.2;

        display: inline-block;

        width: 50%;
        padding: 0 20px 17px; 

        vertical-align: top;
    }
}
.reviews__form-message
{
    margin-bottom: 12px;
}
@media (min-width: 1024px)
{
    .reviews__form-message
    {
        margin-bottom: 26px;
    }
}
.reviews__form-btn
{
    max-width: 144px;
    margin: 0 auto;
}
.reviews__form-btn .btn
{
    padding: 16px 10px 16px;
}
@media (min-width: 640px)
{
    .reviews__form-btn
    {
        margin: 0;
        margin-left: auto;
    }
}
@media (min-width: 640px)
{
    .reviews__holder
    {
        padding: 0 10px;
    }
}
@media (min-width: 767px)
{
    .reviews__holder
    {
        padding: 0 24px;
    }
}
.reviews__item
{
    margin-bottom: 31px; 
    padding: 30px 20px 18px;

    border: 1px solid #e3e3e3;
    background-color: #fff;
}
@media (min-width: 640px)
{
    .reviews__item
    {
        padding: 32px 17px 40px;
    }
}
@media (min-width: 767px)
{
    .reviews__item
    {
        margin-bottom: 27px; 
        padding: 31px 57px 27px;
    }
}
@media (min-width: 1024px)
{
    .reviews__item
    {
        margin-bottom: 22px; 
        padding: 31px 57px 26px;
    }
}
.reviews__item:last-child
{
    margin-bottom: 0;
}
.reviews__item--answer
{
    position: relative;

    margin-left: 31px;
}
@media (min-width: 767px)
{
    .reviews__item--answer
    {
        margin-left: 38px;
    }
}
@media (min-width: 1024px)
{
    .reviews__item--answer
    {
        margin-left: 96px;
    }
}
.reviews__item--answer:before
{
    position: absolute;
    top: 71px;
    left: -30px;

    width: 20px;
    height: 17px;

    content: '';

    border-bottom: 3px solid #3539ed; 
    border-left: 3px solid #3539ed;
}
@media (min-width: 640px)
{
    .reviews__item--answer:before
    {
        top: -15px;
    }
}
@media (min-width: 767px)
{
    .reviews__item--answer:before
    {
        top: -12px;
        left: -33px;
    }
}
@media (min-width: 1024px)
{
    .reviews__item--answer:before
    {
        top: -17px;
        left: -29px;
    }
}
.reviews__item-head
{
    font-size: 0;
    line-height: 0;

    padding-bottom: 16px;
}
@media (min-width: 1024px)
{
    .reviews__item-head
    {
        padding-bottom: 14px;
    }
}
.reviews__item-name
{
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;

    display: inline-block;

    padding-right: 23px; 

    vertical-align: bottom;
}
.reviews__item-date
{
    font-family: 'Oswald';
    font-size: 14px;
    line-height: 24px;

    display: inline-block;

    vertical-align: bottom;

    color: #8a8a8a;
}
.reviews__item-time
{
    display: inline-block;

    padding-left: 10px;
}
.reviews__item-descr
{
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;

    padding-bottom: 11px;
}
@media (min-width: 767px)
{
    .reviews__item-descr
    {
        padding-bottom: 6px;
    }
}
@media (min-width: 1024px)
{
    .reviews__item-descr
    {
        line-height: 23px;

        padding-bottom: 11px;
    }
}
.reviews__item-link
{
    font-size: 15px;
    font-weight: 300;

    display: inline-block;

    border-bottom: 1px solid transparent;
}
.reviews__item-link:hover
{
    border-bottom-color: #3539ed;
}

@media (min-width: 1024px)
{
    .search--main .search__title
    {
        font-size: 18px;
        line-height: 1.33;
    }
}

.search--large .search__time
{
    position: absolute;
    top: 9px;
    left: 10px;

    width: auto;
}
@media (min-width: 640px)
{
    .search--large .search__time
    {
        top: 19px;
        left: 20px;
    }
}

.search--large .search__time .time__data
{
    font-family: 'Roboto';
    font-size: 11px;

    text-transform: uppercase;
}
@media (min-width: 1024px)
{
    .search--large .search__time .time__data
    {
        margin-bottom: 0;
    }
}

@media (min-width: 640px)
{
    .search--large .search__img
    {
        height: 360px; 

        object-fit: cover;
    }
}

@media (min-width: 767px)
{
    .search--large .search__img
    {
        height: 355px;
    }
}

.search--large .search__holder
{
    position: absolute;
    right: 10px;
    bottom: 7px; 
    left: 10px;
}
@media (min-width: 640px)
{
    .search--large .search__holder
    {
        right: 21px;
        bottom: 7px; 
        left: 21px;
    }
}
@media (min-width: 767px)
{
    .search--large .search__holder
    {
        right: 28px;
        bottom: 38px; 
        left: 28px;
    }
}

.search--large .search__info
{
    width: auto; 
    margin-right: 21px;
    padding-left: 0;

    color: #fff;
}
@media (min-width: 767px)
{
    .search--large .search__info
    {
        margin-right: 17px;
    }
}
.search--large .search__info .icon-svg
{
    fill: #fff;
}

.search--large .search__title
{
    font-size: 20px;
    line-height: 1.4; 

    margin-bottom: 12px;
}
@media (min-width: 640px)
{
    .search--large .search__title
    {
        font-size: 26px; 

        margin-bottom: 16px;
    }
}
@media (min-width: 767px)
{
    .search--large .search__title
    {
        font-size: 20px;
        line-height: 1.47; 

        margin-bottom: 18px;
    }
}

.search--large .search__desc
{
    margin-bottom: 15px;
}
@media (min-width: 640px)
{
    .search--large .search__desc
    {
        margin-bottom: 17px;
    }
}
@media (min-width: 767px)
{
    .search--large .search__desc
    {
        font-size: 17px; 

        margin-bottom: 22px;
    }
}
@media (min-width: 1024px)
{
    .search--large .search__desc
    {
        line-height: 1.48; 

        margin-bottom: 24px;
    }
}
.search--large .search__desc .js-shave-char
{
    font-size: 17px;
    font-weight: 700;
    line-height: 10px;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin-left: 8px;

    text-align: center; 
    vertical-align: middle;

    color: #fff;
    background-color: #3539ed;
}

.search__top
{
    position: relative;
    z-index: 1; 

    margin: 0 -10px 22px;
}
@media (min-width: 640px)
{
    .search__top
    {
        margin: 0 -20px 23px;
    }
}
@media (min-width: 767px)
{
    .search__top
    {
        margin: 0 0 34px;
    }
}

.search__link
{
    position: relative;
    z-index: 1; 

    display: block;

    margin-bottom: 22px;
}
@media (min-width: 640px)
{
    .search__link
    {
        margin-bottom: 25px;
    }
}
@media (min-width: 767px)
{
    .search__link
    {
        margin-bottom: 21px;
    }
}
@media (min-width: 1024px)
{
    .search__link
    {
        margin-bottom: 15px;
    }
}
@media (min-width: 1024px)
{
    .search__link::before
    {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        content: '';
        transition: all .3s ease; 

        opacity: 0;
        background-color: rgba(53, 57, 237, .5);
    }
}
.search__link:hover::before
{
    opacity: 1;
}

.search__img
{
    display: block;

    width: 100%;
    height: auto;
}

.search__holder
{
    font-size: 0;
    line-height: 0; 

    margin-bottom: 12px;
}
@media (min-width: 767px)
{
    .search__holder
    {
        margin-bottom: 14px;
    }
}
@media (min-width: 1024px)
{
    .search__holder
    {
        margin-bottom: 15px;
    }
}

.search__time
{
    font-family: 'Oswald';
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;

    display: inline-block;

    width: 50%;
    padding-right: 5px;

    vertical-align: middle;
    letter-spacing: .56px; 

    color: #8a8a8a;
}
@media (min-width: 767px)
{
    .search__time
    {
        width: 100%; 
        margin-bottom: 10px;
    }
}
@media (min-width: 1024px)
{
    .search__time
    {
        width: 40%; 
        margin-bottom: 0;
    }
}

.search__info
{
    font-family: 'Oswald';
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;

    display: inline-block;

    width: 25%;
    padding-left: 5px;

    vertical-align: middle;
    letter-spacing: .56px; 

    color: #8a8a8a;
}
@media (min-width: 767px)
{
    .search__info
    {
        width: 50%; 
        padding-right: 5px;
        padding-left: 0;
    }
}
@media (min-width: 1024px)
{
    .search__info
    {
        width: 30%; 
        padding-right: 0;
        padding-left: 5px;
    }
}
.search__info .icon-svg
{
    display: inline-block;

    margin-top: -4px;
    margin-right: 5px;

    vertical-align: middle;

    fill: #8a8a8a;
}
.search__info-txt
{
    line-height: 1.3;

    display: inline-block;

    width: calc(100% - 25px); 

    vertical-align: middle;
}

.search__topic
{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;

    display: block;

    margin-bottom: 9px;

    text-transform: uppercase; 

    color: #8a8a8a;
}

.search__title
{
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5; 

    display: block;

    margin-bottom: 11px;

    color: #1f1f1f;
}
@media (min-width: 767px)
{
    .search__title
    {
        font-size: 15px;
        line-height: 1.4; 

        margin-bottom: 13px;
    }
}
@media (min-width: 1024px)
{
    .search__title
    {
        margin-bottom: 14px;
    }
}
.search__title:hover
{
    color: #3539ed;
}

.search__desc
{
    font-size: 15px;
    font-weight: 300;
    line-height: 1.4; 

    display: block;

    color: #1f1f1f;
}

.search__more
{
    font-size: 14px;
    font-weight: 400;

    text-transform: uppercase;
}
.search__more:hover
{
    color: #1f1f1f;
}
.search__more:hover .search__more-icon,
.search__more:hover .search__more-icon::before,
.search__more:hover .search__more-icon::after
{
    border-color: #1f1f1f;
}
.search__more-icon
{
    position: relative;
    z-index: 1;

    display: inline-block;

    width: 16px;
    height: 16px;
    margin-right: 11px;

    transition: all .3s ease-in-out; 
    vertical-align: middle;

    border: 2px solid #3539ed;
    border-radius: 50%;
}
.search__more-icon::before
{
    position: absolute;
    top: 50%;
    right: 3px;

    width: 6px;
    height: 6px;
    margin-top: -3px;

    content: '';
    transition: all .3s ease-in-out; 
    transform: rotate(-45deg);

    border-right: 2px solid #3539ed;
    border-bottom: 2px solid #3539ed;
}
.search__more-icon::after
{
    position: absolute;
    top: 50%;
    right: 4px;

    width: 6px;
    margin-top: -1px;

    content: '';
    transition: all .3s ease-in-out; 

    border-bottom: 2px solid #3539ed;
}
.search__more-txt
{
    display: inline-block;

    vertical-align: middle;
}

@media screen and (-webkit-min-device-pixel-ratio: 0)
{
    .search--large .search__desc .js-shave-char
    {
        line-height: 13px;
    }
}

.searches-cards
{
    padding-top: 33px;
    padding-bottom: 30px;
}
@media (min-width: 640px)
{
    .searches-cards
    {
        padding-top: 65px;
    }
}
@media (min-width: 767px)
{
    .searches-cards
    {
        padding-top: 26px;
    }
}
@media (min-width: 1024px)
{
    .searches-cards
    {
        padding-top: 23px;
    }
}
.searches-cards__title
{
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4; 

    display: block;

    margin-bottom: 32px;

    color: #1f1f1f;
}
@media (min-width: 640px)
{
    .searches-cards__title
    {
        font-size: 26px;
        line-height: 1.08;
    }
}
@media (min-width: 767px)
{
    .searches-cards__title
    {
        line-height: .92; 

        margin-bottom: 31px;
    }
}
@media (min-width: 1024px)
{
    .searches-cards__title
    {
        font-size: 32px;
        line-height: .75; 

        margin-bottom: 46px;
    }
}
@media (min-width: 640px)
{
    .searches-cards__list
    {
        font-size: 0;
        line-height: 0; 

        margin: 0 -16px;
    }
}
@media (min-width: 767px)
{
    .searches-cards__list
    {
        margin-bottom: 44px;
    }
}
@media (min-width: 1024px)
{
    .searches-cards__list
    {
        margin-bottom: 66px;
    }
}
.searches-cards__item
{
    margin-bottom: 37px;
}
@media (min-width: 640px)
{
    .searches-cards__item
    {
        font-size: 16px;
        line-height: 1.2; 

        display: inline-block;

        padding: 0 16px;

        vertical-align: top;
    }
}
@media (min-width: 767px)
{
    .searches-cards__item
    {
        margin-bottom: 29px;
    }
}
@media (min-width: 1024px)
{
    .searches-cards__item
    {
        margin-bottom: 42px;
    }
}

.social__list
{
    font-size: 0;
    line-height: 0; 

    margin: 0 -7px;
}

.social__item
{
    display: inline-block;

    padding: 0 7px;

    vertical-align: middle;
}
.social__item-link
{
    display: block;
}
.social__item-link .icon-svg
{
    transition: all .2s ease; 

    fill: #1f1f1f;
}
.social__item-link:hover .icon-svg
{
    fill: #3539ed;
}

.subscribe__form-holder
{
    position: relative;
}
@media (min-width: 767px)
{
    .subscribe__form-holder
    {
        font-size: 0;
        line-height: 0;
    }
}

.subscribe__form-box
{
    display: block;

    padding-bottom: 82px;
}
@media (min-width: 640px)
{
    .subscribe__form-box
    {
        padding-bottom: 72px;
    }
}
@media (min-width: 767px)
{
    .subscribe__form-box
    {
        position: relative; 

        display: inline-block;

        width: calc(100% - 199px);
        padding-right: 29px;
        padding-bottom: 0;

        vertical-align: top;
    }
}

.subscribe__form-icon
{
    display: none;

    cursor: pointer;
}
.subscribe__form-icon:hover svg
{
    fill: #3539ed;
}
.subscribe__form-icon svg
{
    transition: all .3s ease-in-out; 

    fill: #8a8a8a;
}
@media (min-width: 767px)
{
    .subscribe__form-icon
    {
        position: absolute;
        top: 13px;
        left: 18px; 

        display: block;
    }
}

.subscribe__form-input
{
    font-family: 'Oswald';
    font-size: 16px;
    font-weight: 300;

    height: 50px; 
    padding: 3px 16px 4px;

    text-align: center;
}
@media (min-width: 767px)
{
    .subscribe__form-input
    {
        padding: 4px 16px 5px 51px;

        text-align: left;
    }
}

.subscribe__form-btn
{
    max-width: 199px;
    margin: 0 auto;
}

@-moz-document url-prefix()
{
    .subscribe__form-btn .btn
    {
        padding: 15.5px 24px;
    }
}
@media (min-width: 767px)
{
    .subscribe__form-btn
    {
        display: inline-block;

        width: 199px; 

        vertical-align: top;
    }
}

.subscribe__form-txt
{
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;

    position: absolute;
    bottom: 67px;
    left: 50%;

    width: 100%; 

    transform: translateX(-50%);
}
@media (min-width: 640px)
{
    .subscribe__form-txt
    {
        bottom: 82px;
    }
}
@media (min-width: 767px)
{
    .subscribe__form-txt
    {
        position: static;

        padding-top: 22px; 

        transform: translateX(0);
    }
}

.subscribe
{
    position: relative; 

    padding: 34px 0 38px;

    text-align: center;

    color: #fff;
    background-color: #3539ed;
}
.subscribe:before
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 10px;

    content: '';

    background-image: url(../img/border-decor.jpg);
}
@media (min-width: 640px)
{
    .subscribe
    {
        padding: 55px 0 48px;
    }
}
@media (min-width: 767px)
{
    .subscribe
    {
        padding: 50px 0 58px;
    }
}
.subscribe__holder
{
    max-width: 650px;
    margin: 0 auto;
}
.subscribe__head
{
    padding-bottom: 12px;
}
@media (min-width: 640px)
{
    .subscribe__head
    {
        padding-bottom: 20px;
    }
}
@media (min-width: 767px)
{
    .subscribe__head
    {
        font-size: 0;
        line-height: 0;

        display: inline-block;

        padding-bottom: 19px;
    }
}
.subscribe__head-icon
{
    display: block;

    padding-bottom: 16px;
}
@media (min-width: 640px)
{
    .subscribe__head-icon
    {
        padding-bottom: 15px;
    }
}
@media (min-width: 767px)
{
    .subscribe__head-icon
    {
        display: inline-block;

        width: 33px;
        height: 33px; 

        vertical-align: bottom;
    }
}
.subscribe__head-icon svg
{
    display: inline-block; 

    fill: #fff;
}
.subscribe__head-txt
{
    font-size: 20px;
    font-weight: 700;
    line-height: 28px; 

    display: block;
}
@media (min-width: 767px)
{
    .subscribe__head-txt
    {
        font-size: 26px;
        line-height: 24px;

        display: inline-block;

        width: calc(100% - 33px);
        padding-left: 23px;

        vertical-align: bottom;
        white-space: nowrap;
    }
}
.subscribe__descr
{
    display: none;
}
@media (min-width: 767px)
{
    .subscribe__descr
    {
        display: block;

        padding-bottom: 29px; 

        letter-spacing: -.25px;
    }
}

.time
{
    display: flex;
    flex-direction: column;

    width: 65px;
    height: 65px;

    background-color: #3539ed; 

    justify-content: center;
    align-items: center;
}
.time__data
{
    font-size: 11px;
    font-weight: 300; 

    display: inline-flex;

    margin-bottom: 3px;

    color: #fff;
}
.time__txt
{
    font-family: 'Oswald';
    font-size: 18px;
    font-weight: 300;

    display: inline-flex;

    letter-spacing: .72px; 

    color: #fff;
}
