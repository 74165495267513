.subscribe {
  background-color: #3539ed;
  color: #fff;
  padding: 34px 0 38px;
  text-align: center;
  position: relative;

  &:before {
    content: '';
    height: 10px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../img/border-decor.jpg);
  }

  @include b(mobile) {
    padding: 55px 0 48px;
  }

  @include b(tablet) {
    padding: 50px 0 58px;
  }

  &__holder {
    max-width: 650px;
    margin: 0 auto;
  }

  &__head {
    padding-bottom: 12px;

    @include b(mobile) {
      padding-bottom: 20px;
    }

    @include b(tablet) {
      font-size: 0;
      line-height: 0;
      display: inline-block;
      padding-bottom: 19px;
    }

    &-icon {
      display: block;
      padding-bottom: 16px;

      @include b(mobile) {
        padding-bottom: 15px;
      }

      @include b(tablet) {
        display: inline-block;
        vertical-align: bottom;
        width: 33px;
        height: 33px;
      }

      svg {
        fill: #fff;
        display: inline-block;
      }
    }

    &-txt {
      display: block;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;

      @include b(tablet) {
        font-size: 26px;
        line-height: 24px;
        width: calc(100% - 33px);
        display: inline-block;
        vertical-align: bottom;
        padding-left: 23px;
        white-space: nowrap;
      }
    }
  }

  &__descr {
    display: none;

    @include b(tablet) {
      display: block;
      letter-spacing: -.25px;
      padding-bottom: 29px;
    }
  }
}
