.radio {
  display: block;
  cursor: pointer;

  input[type='radio'] {
    display: none;

    &:checked + span {

      &:after {
        opacity: 1;
      }
    }
  }

  span {
    display: block;
    padding: 0 0 0 30px;
    font-size: $main-font-size;
    line-height: $main-font-height;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      top: 50%;
      left: 0;
      margin: -8px 0 0 0;
      background: #ddd;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 8px;
      width: 8px;
      top: 50%;
      left: 4px;
      margin: -4px 0 0 0;
      background: $accent;
      border-radius: 50%;
      opacity: 0;
      @include trs;
    }
  }
}
