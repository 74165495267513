// В этом файле должны быть стили для БЭМ-блока social, его элементов, 
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...

.social {

  &__list {
    margin: 0 -7px;
    font-size: 0;
    line-height: 0;
  }

  &__item {
    padding: 0 7px;
    display: inline-block;
    vertical-align: middle;

    &-link {
      display: block;

      .icon-svg {
        fill: $default;
        transition: all .2s ease;
      }

      &:hover .icon-svg {
        fill: $accent;
      }
    }
  }
}
