.search {
  position: relative;
  z-index: 1;

  &__btn {
    position: absolute;
    padding: 0;
    top: 0;
    bottom: 0;
    right: 10px;
    background-color: transparent;
    width: 33px;

    @include b(tablet) {
      right: 7px;
    }

    &::before {
      display: none;
    }

    .icon-svg {
      fill: $default;
      transition: all .2s ease;
    }

    &:hover {

      .icon-svg {
        fill: $accent;
      }
    }
  }

  .input {
    padding: 10px 45px 11px;
    font-size: 15px;
    line-height: 1.33;
    text-align: center;
    letter-spacing: normal;
    width: 100%;

    @include b(mobile) {
      padding-left: 18px;
      text-align: left;
    }
  }
}
