*,
*:before,
*:after {
  box-sizing: border-box;
  outline: 0;
}

body {
  height: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
}

html {
  height: 100%;
}

.container {
  padding: 0 10px;
  max-width: $container-width;
  margin: 0 auto;
  @include clearfix;

  @include b(mobile) {
    padding: 0 20px;
  }
}

.wrapper {
  flex: 1 0 auto;
  overflow: hidden;
}

.page {

  @include b(tablet) {
    padding-top: 33px;
  }

  @include b(desktop) {
    padding-top: 61px;
  }
}


.sidebar {

  @include b(tablet) {
    display: block;
    padding-right: 15px;
    float: left;
    width: 237px;
  }

  @include b(desktop) {
    width: 324px;
  }
}

.article {

  @include b(tablet) {
    padding-left: 15px;
    float: right;
    width: calc(100% - 237px);
  }

  @include b(desktop) {
    width: calc(100% - 324px);
  }
}
