// В этом файле должны быть стили для БЭМ-блока searches-cards, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...

.searches-cards {
  padding-top: 33px;
  padding-bottom: 30px;

  @include b(mobile) {
    padding-top: 65px;
  }

  @include b(tablet) {
    padding-top: 26px;
  }

  @include b(desktop) {
    padding-top: 23px;
  }

  &__title {
    margin-bottom: 32px;
    display: block;
    color: $default;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;

    @include b(mobile) {
      font-size: 26px;
      line-height: 1.08;
    }

    @include b(tablet) {
      margin-bottom: 31px;
      line-height: .92;
    }

    @include b(desktop) {
      margin-bottom: 46px;
      font-size: 32px;
      line-height: .75;
    }
  }

  &__list {

    @include b(mobile) {
      margin: 0 -16px;
      font-size: 0;
      line-height: 0;
    }

    @include b(tablet) {
      margin-bottom: 44px;
    }

    @include b(desktop) {
      margin-bottom: 66px;
    }
  }

  &__item {
    margin-bottom: 37px;

    @include b(mobile) {
      padding: 0 16px;
      display: inline-block;
      vertical-align: top;
      //width: 50%;
      //width: 100%;
      font-size: $main-font-size;
      line-height: $main-font-height;
    }

    @include b(tablet) {
      margin-bottom: 29px;
      //width: 100%;
    }

    @include b(desktop) {
      margin-bottom: 42px;
    }

    @include b(1150) {
      //width: 33.33%;
      //width: 100%;
    }
  }

}
