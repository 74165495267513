.article-desc {
  padding-top: 9px;
  padding-bottom: 15px;

  @include b(mobile) {
    padding-top: 42px;
    padding-bottom: 38px;
  }

  @include b(tablet) {
    margin-bottom: 66px;
    padding-top: 30px;
    padding-bottom: 10px;
    border: 1px solid #e3e3e3;
    background-color: #f5f5f5;
  }

  @include b(desktop) {
    margin-bottom: 80px;
    margin-top: 30px;
    padding: 45px 0 64px;
  }

  &__title {
    display: none;

    @include b(mobile) {
      margin-bottom: 20px;
      display: block;
      color: $default;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.19;
    }

    @include b(tablet) {
      margin-bottom: 23px;
      padding: 0 21px;
    }

    @include b(desktop) {
      margin-bottom: 42px;
      padding: 0 54px;
      font-size: 42px;
      line-height: 1.24;
    }
  }

  &__header {
    margin: 0 -10px 33px;
    position: relative;
    z-index: 1;

    @include b(mobile) {
      margin: 0 -20px 36px;
    }

    @include b(tablet) {
      margin: 0 0 34px;
    }

    @include b(desktop) {
      margin-bottom: 64px;
    }
  }

  &__time {
    position: absolute;
    top: 19px;
    left: 21px;
    width: auto;
    display: none;

    @include b(mobile) {
      display: block;
    }

    @include b(desktop) {
      display: none;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: 238px;
    object-fit: cover;

    @include b(mobile) {
      height: 360px;
    }

    @include b(tablet) {
      height: 355px;
    }

    @include b(desktop) {
      height: 480px;
    }
  }

  &__body {

    @include b(mobile) {
      margin-bottom: 37px;
    }

    @include b(tablet) {
      margin-bottom: 48px;
      padding-left: 20px;
      padding-right: 20px;
    }

    @include b(desktop) {
      margin-bottom: 61px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .static-content {

    p {

      @include b(mobile) {
        padding-left: 20px;
        padding-right: 20px;
      }

      @include b(tablet) {
        padding-left: 0;
        padding-right: 0;
      }

      @include b(desktop) {
        margin-left: auto;
        margin-right: auto;
        max-width: 760px;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__footer {

    @include b(mobile) {
      position: relative;
      z-index: 1;
      text-align: center;
    }

    @include b(tablet) {
      margin-left: 22px;
      margin-right: 22px;
    }

    @include b(desktop) {
      margin-left: 52px;
      margin-right: 52px;
    }

    &::before {

      @include b(mobile) {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        border-bottom: 1px solid #ccc;
        z-index: -1;
      }
    }

    &-holder {

      @include b(mobile) {
        padding: 0 31px;
        display: inline-block;
        vertical-align: middle;
        background-color: $white;
        text-align: center;
      }

      @include b(tablet) {
        background-color: #f5f5f5;
      }
    }

    &-decor {

      @include b(mobile) {
        margin: 0 7px;
        display: inline-block;
        vertical-align: middle;
        width: 12px;
        height: 12px;
        border: 4px solid #cccccc;
        background-color: $white;
      }
    }
  }

  &__base {

    @include b(tablet) {
      padding: 43px 20px 0;
    }

    @include b(desktop) {
      padding: 48px 53px 0;
    }

    .base {
      background-color: $white;
    }
  }
}
