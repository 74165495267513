// В этом файле должны быть стили для БЭМ-блока base-top, его элементов, 
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...

.base-top {
  display: block;
  position: relative;
  z-index: 2;

  @include b(tablet) {
    height: 0;
    overflow-y: hidden;
  }

  &__btn {
    margin: 0 -10px 8px;
    padding-top: 16px;
    padding-bottom: 15px;
    width: calc(100% + 20px);

    @include b(mobile) {
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px);
    }

    &::before {
      display: none;
    }

    .icon-svg {
      margin-right: 14px;
      display: inline-block;
      vertical-align: middle;
      fill: $white;
    }

    &-txt {
      display: inline-block;
      vertical-align: middle;
      font-size: 15px;
      font-weight: 700;
    }
  }

  &__collapse {
    position: absolute;
    top: 0;
    left: -10px;
    right: -10px;
    z-index: 5;
    background-color: $white;
    box-shadow: 0 3px 0 $orange;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    transform: translateY(20%);

    @include b(mobile) {
      left: -20px;
      right: -20px;
      padding: 0 20px 9px;
      box-shadow: none;
    }

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  &__close {
    position: absolute;
    top: 31px;
    right: 28px;
    width: 14px;
    height: 14px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 6px;
      left: -2px;
      width: 18px;
      border-bottom: 1px solid $default;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__search {
    background-color: $white;

    @include b(mobile) {
      display: none;
    }
  }
}
