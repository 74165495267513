.slick {

  &-arrow {
    margin: -8px 0 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    border: 0;
    box-shadow: none;
    width: 10px;
    height: 16px;
    background: transparent;
    position: absolute;
    z-index: 1;
    top: 50%;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -6px;
      width: 12px;
      height: 12px;
      border-bottom: 1px solid $default;

      @include b(desktop) {
        margin-top: -5px;
        width: 10px;
        height: 10px;
      }
    }

    &:hover::before {
      border-color: $accent;
    }
  }

  &-prev {
    left: 22px;

    @include b(desktop) {
      left: 34px;
    }

    &::before {
      left: 2px;
      transform: rotate(45deg);
      border-left: 1px solid $default;
    }
  }

  &-next {
    right: 22px;

    @include b(desktop) {
      right: 34px;
    }

    &::before {
      right: 2px;
      transform: rotate(-45deg);
      border-right: 1px solid $default;
    }
  }

  &-dots {
    font-size: 0;
    line-height: 0;
    text-align: center;

    li {
      margin: 0 7px;
      display: inline-block;
      vertical-align: middle;

      &.slick-active button {
        border-color: $default;
      }

      &.slick-active button::before {
        opacity: 1;
      }
    }

    button {
      background: transparent;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #d3d3d3;
      cursor: pointer;
      padding: 0;
      position: relative;
      z-index: 1;
      transition: all .3s ease-in-out;

      @include b(desktop) {
        width: 17px;
        height: 17px;
      }

      &:hover {
        border-color: $accent;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -4px 0 0 -4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $default;
        opacity: 0;

        @include b(desktop) {
          margin: -3.5px 0 0 -3.5px;
          width: 7px;
          height: 7px;
        }
      }
    }
  }
}
