.about {
  margin-top: 10px;
  padding-bottom: 20px;

  @include b(mobile) {
    margin-top: 0;
    padding-bottom: 21px;
  }

  @include b(tablet) {
    padding-bottom: 20px;
  }

  @include b(desktop) {
    padding-top: 33px;
    padding-bottom: 28px;
  }

  &__holder {
    padding: 28px 10px 18px;
    margin: 0 -10px;
    background-color: #f5f5f5;
    text-align: center;
    border-top: 1px solid #e3e3e3;

    @include b(mobile) {
      padding: 39px 20px 18px;
      margin: 0 -20px;
    }

    @include b(tablet) {
      margin: 0;
      border: 1px solid #e3e3e3;
      padding: 31px 20px 17px;
    }

    @include b(desktop) {
      padding: 40px 55px 51px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    display: block;
    padding-bottom: 21px;

    @include b(mobile) {
      text-align: left;
      font-size: 26px;
      line-height: 28px;
      padding-bottom: 29px;
    }

    @include b(tablet) {
      font-size: 32px;
      line-height: 38px;
      padding-bottom: 33px;
    }

    @include b(desktop) {
      font-size: 42px;
      line-height: 52px;
      padding-bottom: 46px;
    }
  }

  &__row {

    @include b(large) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      margin: 0 -11px;
    }
  }

  &__col {
    margin-bottom: 14px;

    @include b(mobile) {
      margin-bottom: 21px;
    }

    @include b(tablet) {
      margin-bottom: 18px;
    }

    @include b(large) {
      display: flex;
      //width: 33.33%;
      width: 50%;
      padding: 0 11px;
    }
  }

  &__item {
    border: 1px solid #e3e3e3;
    background-color: #fff;
    padding: 47px 20px 37px;

    @include b(mobile) {
      padding: 47px 20px 33px;
    }

    @include b(tablet) {
      @include rlist;
      padding: 47px 32px 31px;
    }

    @include b(large) {
      padding: 47px 42px 51px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-icon {
      width: 73px;
      height: 75px;
      margin: 0 auto 37px;

      @include b(tablet) {
        @include ulist;
        vertical-align: top;
      }

      @include b(large) {
        display: block;
        margin: 0 auto 42px;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    &-info {

      @include b(tablet) {
        @include ulist;
        vertical-align: top;
        width: calc(100% - 73px);
        text-align: left;
        padding-left: 31px;
        margin-top: -5px;
      }

      @include b(large) {
        display: block;
        width: 100%;
        padding-left: 0;
      }
    }

    &-name {
      display: block;
      color: $accent;
      font-size: 15px;
      line-height: 21px;
      font-weight: 700;
      padding-bottom: 10px;
    }

    &-descr {
      font-size: 15px;
      line-height: 21px;

      @include b(desktop) {
        letter-spacing: -.2px;
      }
    }
  }
}
