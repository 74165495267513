.block-interest {
  display: none;
  padding-bottom: 59px;

  @include b(mobile) {
    display: block;
  }

  @include b(tablet) {
    padding-bottom: 31px;
  }

  @include b(desktop) {
    padding-bottom: 0;
    margin-bottom: -6px;
  }

  &__title {
    display: block;
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    padding-bottom: 50px;

    @include b(tablet) {
      padding-bottom: 43px;
    }

    @include b(desktop) {
      font-size: 32px;
      line-height: 24px;
      padding-bottom: 61px;
    }
  }

  &__row {
    @include rlist;
    margin: 0 -15px;
  }

  &__col {
    @include ulist;
    vertical-align: top;
    width: 50%;
    padding: 0 15px 29px;

    @include b(tablet) {
      padding: 0 15px 25px;
    }

    @include b(large) {
      width: 33.33%;
      padding: 0 15px 66px;
    }

    &:nth-child(5) {
      display: none;

      @include b(desktop) {
        display: inline-block;
      }
    }

    &:nth-child(6) {
      display: none;

      @include b(desktop) {
        display: inline-block;
      }
    }
  }
}
