.header {
  margin: 0 -10px;
  background-color: #efefef;
  box-shadow: 0 1px 0 #e3e3e3;

  @include b(mobile) {
    margin-right: -20px;
  }

  @include b(tablet) {
    margin: 0;
  }

  &__wrap {
    padding-left: 10px;
    font-size: 0;
    line-height: 0;

    @include b(mobile) {
      padding-left: 20px;
    }

    @include b(tablet) {
      padding: 27px 0 18px;
    }

    @include b(desktop) {
      padding: 17px 0 12px;
    }
  }

  &__logo {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 42px);

    @include b(mobile) {
      padding-right: 10px;
      width: 270px;
    }

    @include b(tablet) {
      padding-right: 15px;
      width: 50%;
      vertical-align: top;
    }

    @include b(desktop) {
      width: 41%;
    }

    &-link {
      display: block;
      font-size: 0;
      line-height: 0;

      &:hover {
        opacity: .75;
      }
    }

    &-img {
      margin-right: 15px;
      display: inline-block;
      vertical-align: middle;
      width: 37px;
      height: 37px;

      @include b(mobile) {
        margin-right: 14px;
      }

      @include b(tablet) {
        margin-right: 19px;
        vertical-align: top;
      }

      @include b(desktop) {
        margin-right: 14px;
        vertical-align: middle;
      }
    }

    &-right {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 52px);
      font-size: $main-font-size;
      line-height: $main-font-height;

      @include b(mobile) {
        width: calc(100% - 51px);
      }

      @include b(tablet) {
        padding-top: 8px;
        vertical-align: top;
        width: calc(100% - 56px);
      }

      @include b(desktop) {
        padding-top: 0;
        vertical-align: middle;
      }
    }

    &-name {
      margin-bottom: 5px;
      display: block;
      color: $default;
      font-size: 20px;
      font-weight: 800;
      line-height: 1;
      text-transform: uppercase;

      @include b(tablet) {
        margin-bottom: 8px;
      }

      @include b(desktop) {
        margin-right: 16px;
        margin-bottom: 8px;
        display: inline-block;
        vertical-align: middle;
      }

      @include b(1260) {
        margin-bottom: 0;
      }
    }

    &-subname {
      display: block;
      color: $default;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.33;

      @include b(tablet) {
        font-size: 17px;
        line-height: 1.18;
      }

      @include b(desktop) {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        line-height: 1.11;
      }
    }
  }

  &__holder {
    display: none;

    @include b(mobile) {
      padding: 0 19px 0 30px;
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 312px);
    }

    @include b(tablet) {
      padding-right: 0;
      padding-left: 15px;
      width: 50%;
    }

    @include b(desktop) {
      padding-left: 19px;
      display: inline-flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 59%;
    }
  }

  &__search {
    display: block;
    width: 100%;

    @include b(desktop) {
      padding-right: 15px;
      width: 50%;
    }
  }

  &__info {
    display: none;

    @include b(tablet) {
      margin-bottom: 9px;
      display: block;
    }

    @include b(desktop) {
      margin-bottom: 0;
      padding-left: 15px;
      width: 50%;
    }

    @include b(1300) {
      padding-left: 41px;
    }

    &-inner {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 97px);
    }

    &-link {
      display: inline-block;
      vertical-align: middle;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.2;
      cursor: pointer;

      .icon-svg {
        margin-right: 12px;
        display: inline-block;
        vertical-align: middle;
        fill: $accent;
        transition: all .3s ease-in-out;
      }

      &:hover {
        color: $default;

        .icon-svg {
          fill: $default;
        }
      }
    }

    &-txt {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 31px);
    }
  }

  &__social {
    display: inline-block;
    vertical-align: middle;
    width: 97px;
    text-align: right;
  }

  &__btn {
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 71px;
    background-color: $accent;
    position: relative;
    z-index: 1;

    @include b(tablet) {
      display: none;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 10px;
      width: 22px;
      border-top: 3px solid $white;
    }

    &::before {
      top: 28px;
    }

    &::after {
      bottom: 28px;
    }

    &-decor {
      position: absolute;
      top: 34px;
      left: 10px;
      width: 22px;
      border-top: 3px solid $white;
    }
  }
}
