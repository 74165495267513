.block-articles {
  margin: 0 -10px;
  padding: 32px 10px 0;
  background-color: #f5f5f5;
  border-top: 1px solid #e3e3e3;

  @include b(mobile) {
    margin: 0;
    padding: 0;
    border-top: none;
    background-color: transparent;
    padding-bottom: 27px;
  }

  @include b(tablet) {
    padding-bottom: 35px;
  }

  @include b(desktop) {
    padding-bottom: 20px;
  }



  &__title {
    display: block;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 32px;

    @include b(mobile) {
      font-size: 26px;
      padding-bottom: 36px;
    }

    @include b(tablet) {
      padding-bottom: 30px;
    }

    @include b(desktop) {
      font-size: 32px;
      line-height: 24px;
      padding-bottom: 50px;
    }
  }

  &__row {

    @include b(mobile) {
      @include rlist;
      margin: 0 -15px;
    }
  }

  &__col {
    padding-bottom: 37px;

    @include b(mobile) {
      @include ulist;
      vertical-align: top;
      width: 50%;
      padding: 0 15px 34px;
    }

    @include b(tablet) {
      width: 100%;
      padding: 0 15px 28px;

      .card__time {
        width: 40%;
        margin-bottom: 0;
      }

      .card__info {
        width: 30%;
        padding-right: 0;
        padding-left: 5px;
      }
    }

    @include b(desktop) {
      width: 50%;
    }

    @include b(large) {
      width: 33.33%;
      padding: 0 15px 63px;
    }
  }

  &__load {
    height: 24px;
    width: 24px;
    margin: 0 auto 0;
    padding: 15px 0 74px;

    img {
      animation: rotation 2s infinite linear;
    }

    @include b(mobile) {
      display: none;
    }
  }
}

@keyframes rotation {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
