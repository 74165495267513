//----------------------------------------------------//
// TYPOGRAPHY                                         //
//----------------------------------------------------//
body {
  background: #fff;
  color: $main-font-color;
  font-family: $main-font, $fix-font;
  font-size: $main-font-size;
  font-weight: $main-font-weight;
  line-height: $main-font-height;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $default;
  font-family: $main-font, $fix-font;
  margin: 0;
  padding: 0;
  font-weight: 700;
  line-height: 1.24;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

p {
  padding: 0;
  margin: 0;
}

hr {
  overflow: hidden;
  height: 1px;
  background: $hr-color;
  border: 0;
  margin: $hr-margin;
}

q,
blockquote {
  text-align: center;
  display: block;
  font-style: italic;
}

strong,
b {
  font-weight: 700;
}

a {
  color: $link-color;
  transition: $global-transition;
  text-decoration: none;

  &.button {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

[class*='icon-'] {
  display: inline-block;
  vertical-align: top;
}

img[style*='float: left'],
img[style*='float:left'],
img[align='left'] {
  margin: 0 20px 5px 0;
}

img[style*='float: right'],
img[style*='float:right'],
img[align='right'] {
  margin: 0 0 5px 20px;
}

.static-content {

  blockquote {
    text-align: left;
    border-left: 2px solid $medium-gray;
    padding-left: 20px;
  }

  ul {
    margin: 20px 0;

    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        background: $default;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -2px;
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }
  }

  ol {
    margin: 20px 0;
    list-style-type: decimal;
    list-style-position: inside;
    counter-reset: item;

    li {
      display: block;
      position: relative;

      &:before {
        content: counters(item, '.') '. ';
        counter-increment: item;
        display: inline;
        vertical-align: top;
        font-weight: 700;
      }
    }
  }

  p {
    margin-bottom: 21px;
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;

    @include b(mobile) {
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 25px;
    }

    @include b(desktop) {
      line-height: 27px;
      margin-bottom: 27px;
      font-weight: 400;
    }

    & + img {
      margin-top: 8px;

      @include b(tablet) {
        margin-top: 25px;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      text-align: left;
      font-weight: 700;
      border-bottom: 1px solid #000;
      padding: 5px;
    }

    td {
      padding: 5px;
    }

    tr {

      &:nth-child(odd) {

        td {
          background-color: #f4f4f4;
        }
      }
    }
  }

  img {
    margin-bottom: 12px;
    max-width: 100%;

    @include b(mobile) {
      margin-bottom: 20px;
    }

    @include b(tablet) {
      margin-bottom: 13px;
    }

    @include b(tablet) {
      height: 320px;
      object-fit: cover;
    }

    @include b(desktop) {
      margin-bottom: 20px;
    }

    & + p {
      margin-top: 12px;

      @include b(mobile) {
        margin-top: 20px;
      }

      @include b(tablet) {
        margin-top: 26px;
      }

      @include b(desktop) {
        margin-top: 41px;
      }
    }
  }
}

.upper {
  text-transform: uppercase;
}
