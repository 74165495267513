.email {
  display: block;
  position: relative;
  z-index: 1;

  &-icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -10px;
    width: 17px;
    height: 20px;

    @include b(tablet) {
      display: block;
    }

    .icon-svg {
      fill: $gray;
    }
  }

  .input {
    padding-top: 12px;
    padding-bottom: 14px;
    text-align: center;

    @include b(tablet) {
      padding-left: 53px;
      text-align: left;
    }
  }
}
