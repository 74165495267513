textarea,
select,
.input {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  color: $default;
  font-size: 17px;
  line-height: 2.47;
  padding: 1px 16px 2px;
  width: 100%;
  display: block;
  transition: $global-transition;
  appearance: none;
  border-radius: 0;
  letter-spacing: .68px;
  @include placeholderColor($gray);

  &:focus {
    border-color: $accent;
    background-color: #fff;
  }

  &.disabled,
  &[disabled='disabled'],
  &[disabled] {
    background-color: #f3f3f3;
  }

  &--inverse {
    font-family: $second-font;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.31;
  }
}

textarea {
  height: 166px;
  resize: none;
  padding: 28px 16px 2px;
  line-height: 1.4;
}

input[type='file'] {
  display: none;

  & + label {
    display: block;
    background: #fff;
    font-size: 14px;
    line-height: 24px;
    padding: 25px 15px;
    color: $gray;
    text-align: center;
    border: 1px dashed #c4c4c4;
    @include trs;

    &:hover {
      border-color: #696969;
      background: #e9e9e9;
    }
  }
}

@include ie() {

  input {
    height: 46px;
  }
}
