//  Table of Contents:
//
//   1. Global
//   2. Colors
//   3. Fonts
//   4. Typography
//   5. Other

//----------------------------------------------------//
// 1. Global                                          //
//----------------------------------------------------//
$global-transition: all .3s ease-in-out;
$global-shadow: 1px 1px 10px rgba(0, 0, 0, .4);
$global-radius: 5px;
$global-rounded: 30px;
$global-padding: 16px;
$global-margin: 16px;

$container-width: 1366px;
$container-padding: 20px;

$brake-point-xs: 320;
$brake-point-sm: 768;
$brake-point-md: 1025;
$brake-point-lg: 1266;

//----------------------------------------------------//
// 2. Colors                                          //
//----------------------------------------------------//
$default: #1f1f1f;
$accent: #3539ed;
$orange: #f34e2d;

$link-color: #191fcc;
$spinner-preloader-color: #3539ed;
$spinner-preloader-bg-color: #f1f1f1;
$black: #000;
$white: #fff;
$gray: #8a8a8a;
$medium-gray: #ccc;

//----------------------------------------------------//
// 3. Fonts                                           //
//----------------------------------------------------//
$main-font: 'Roboto';
$main-font-size: 16px;
$main-font-height: 1.2;
$main-font-weight: 400;
$main-font-color: $default;

$second-font: 'Oswald';

$fix-font: 'sans-serif';

//----------------------------------------------------//
// 4. Typography                                      //
//----------------------------------------------------//
$h1-font-size: 42px;
$h2-font-size: 36px;
$h3-font-size: 30px;
$h4-font-size: 28px;
$h5-font-size: 24px;
$h6-font-size: 20px;

$hr-color: #e9e9e9;
$hr-margin: 32px 0;

//----------------------------------------------------//
// 5. Other                                           //
//----------------------------------------------------//
