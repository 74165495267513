// В этом файле должны быть стили для БЭМ-блока time, его элементов, 
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...

.time {
  width: 65px;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $accent;

  &__data {
    margin-bottom: 3px;
    display: inline-flex;
    color: $white;
    font-size: 11px;
    font-weight: 300;
  }

  &__txt {
    display: inline-flex;
    color: $white;
    font-family: $second-font;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: .72px;
  }
}
