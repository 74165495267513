// В этом файле должны быть стили для БЭМ-блока articles-cards, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...

.articles-cards {
  padding-top: 33px;
  padding-bottom: 30px;

  @include b(mobile) {
    padding-top: 65px;
  }

  @include b(tablet) {
    padding-top: 26px;
  }

  @include b(desktop) {
    padding-top: 23px;
  }

  &--categories {
    padding-top: 12px;
    padding-bottom: 48px;

    @include b(mobile) {
      padding-top: 21px;
      padding-bottom: 57px;
    }

    @include b(tablet) {
      padding-top: 16px;
      padding-bottom: 77px;
    }

    .articles-cards__list {

      @include b(tablet) {
        margin-bottom: 4px;
      }

      @include b(desktop) {
        margin-bottom: 16px;
      }
    }

    .articles-cards__item {
      margin-bottom: 30px;

      @include b(tablet) {
        margin-bottom: 39px;
        width: 33.33%;
      }

      @include b(desktop) {
        margin-bottom: 37px;
      }

      @include b(1100) {
        width: 25%;
      }
    }
  }

  &--main {
    padding-top: 12px;
    padding-bottom: 48px;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -10px;
      right: -10px;
      bottom: 0;
      z-index: -1;
      background-color: #f5f5f5;

      @include b(tablet) {
        display: none;
      }
    }

    @include b(mobile) {
      padding-top: 21px;
      padding-bottom: 57px;
    }

    @include b(tablet) {
      padding-top: 10px;
      padding-bottom: 77px;
    }

    .articles-cards__list {

      @include b(tablet) {
        margin-bottom: 4px;
      }

      @include b(desktop) {
        margin-bottom: 16px;
      }
    }

    .articles-cards__item {
      margin-bottom: 30px;

      @include b(tablet) {
        margin-bottom: 28px;
      }

      @include b(desktop) {
        margin-bottom: 37px;
      }
    }
  }

  &--day-news {
    @include b(mobile) {
      padding-top: 21px;
      padding-bottom: 0px;
    }

    @include b(tablet) {
      padding-top: 10px;
      padding-bottom: 0px;
    }

    .articles-cards__list {

      @include b(tablet) {
        margin-bottom: 0px;
      }

      @include b(desktop) {
        margin-bottom: 0px;
      }
    }

    .articles-cards__item {
      margin-bottom: 0px;

      @include b(tablet) {
        margin-bottom: 0px;
      }

      @include b(desktop) {
        margin-bottom: 0px;
      }
    }
  }

  &--sidebar {
    display: none;

    @include b(tablet) {
      display: block;

      .articles-cards__list {
        margin-left: 0;
        margin-right: 0;
      }

      .articles-cards__item {
        padding: 0;
        display: block;
        width: auto;
      }
    }
  }

  &__title {
    margin-bottom: 32px;
    display: block;
    color: $default;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;

    @include b(mobile) {
      font-size: 26px;
      line-height: 1.08;
    }

    @include b(tablet) {
      margin-bottom: 31px;
      line-height: .92;
    }

    @include b(desktop) {
      margin-bottom: 46px;
      font-size: 32px;
      line-height: .75;
    }
  }

  &__list {

    @include b(mobile) {
      margin: 0 -16px;
      font-size: 0;
      line-height: 0;
    }

    @include b(tablet) {
      margin-bottom: 44px;
    }

    @include b(desktop) {
      margin-bottom: 66px;
    }
  }

  &__item {
    margin-bottom: 37px;

    @include b(mobile) {
      padding: 0 16px;
      display: inline-block;
      vertical-align: top;
      width: 50%;
      font-size: $main-font-size;
      line-height: $main-font-height;
    }

    @include b(tablet) {
      margin-bottom: 29px;
    }

    @include b(desktop) {
      margin-bottom: 42px;
    }

    @include b(1150) {
      width: 33.33%;
    }
  }

  &__bottom {
    padding: 0 9px;
  }

  &__btn {
    margin: 0 auto;
    padding-left: 14px;
    padding-right: 14px;
    max-width: 220px;
  }
}
